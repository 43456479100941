import {
  EVENT_DELETE_EVOLUTION,
  EVENT_DUPLICATE_EVOLUTION,
  EVENT_EDIT_EVOLUTION,
  EVENT_PAUSE_POKE,
  EVENT_PLAY_POKE,
} from 'amplitude';
import amplitude from 'amplitude-js';
import classnames from 'classnames';
import Block from 'components/Block';
import Button from 'components/Button';
import LivePreview from 'components/LivePreview';
import LivePreviewNew from 'components/LivePreviewNew';
import {Modal, ModalConfirm} from 'components/Modal';
import {toastDanger, toastSuccess} from 'components/Toaster';
import dayjs from 'dayjs';
import {errorHelpers} from 'helpers';
import {hasFlag} from 'helpers/bitwise';
import {getStepData, getTypeFromBoostFlags} from 'helpers/poke';
import {useUpdateSubscription} from 'hooks/useUpdateSubscription';
import {bool, func, object} from 'prop-types';
import {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {
  ROUTE_BUILDER_CREATE,
  ROUTE_POKE_BUILDER_FROM_TYPE,
} from 'router/routes.const';
import {generalSelector} from 'selectors';
import {evolutionService} from 'services';
import {
  EVOLUTION_STATE_LIVE,
  EVOLUTION_STATE_PAUSED,
  EVOLUTION_TYPE_BANNER,
  EVOLUTION_TYPE_HINT,
  EVOLUTION_TYPE_SURVEY,
  EVOLUTION_TYPE_TOUR,
  F_OPTION_SHOW_ON_PORTAL,
  F_OPTION_V2,
} from 'services/evolution';
import {
  F_EXTRA_CAN_PUBLISH,
  PROJECT_ROLE_ADMIN,
  PROJECT_ROLE_EDITOR,
  PROJECT_ROLE_MEMBER,
} from 'services/project';
import {STEP_TYPE_TEXT_BLOCK} from 'services/steps';
import {PLAN_STARTUP_ID} from 'services/subscription';
import {Swaler} from 'swaler';
import {getIcon} from '../TemplatesModal';
import './_Styles.scss';

const propTypes = {
  template: object.isRequired,
  onUseTemplateClick: func,
  withActions: bool,
  onDelete: func,
  onToggleState: func,
  useDefaultTheme: bool,
};

const defaultProps = {
  onUseTemplateClick: null,
  withActions: false,
  onDelete: () => {},
  onToggleState: () => {},
  useDefaultTheme: false,
};

const logger = new Swaler('PreviewTemplate');

const PreviewTemplate = ({
  template,
  onUseTemplateClick,
  withActions,
  onDelete,
  onToggleState,
  useDefaultTheme,
  stepId,
}) => {
  let {
    steps,
    tourSteps,
    isDraft,
    state,
    lastStepChangeAt,
    expiresAt,
    optionsFlags,
    type,
  } = template;

  const {update} = useUpdateSubscription();

  const history = useHistory();

  const project = useSelector((state) => generalSelector.getProject(state));
  const projectMember = useSelector((state) =>
    generalSelector.getProjectMember(state)
  );

  const isTour = type === EVOLUTION_TYPE_TOUR;
  const isBanner = type === EVOLUTION_TYPE_BANNER;
  const isHint = type === EVOLUTION_TYPE_HINT;

  const [selectedStepId, setSelectedStepId] = useState(null);
  const [selectedConceptStepId, setSelectedConceptStepId] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isTogglingState, setIsTogglingState] = useState(false);
  const [showModalDeleteEvolution, setShowModalDeleteEvolution] =
    useState(false);
  const [
    showModalOptionalDeleteEvolution,
    setShowModalOptionalDeleteEvolution,
  ] = useState(false);

  const previewRef = useRef();

  const handleSelectedStepId = (stepId) => {
    previewRef?.current?.sendMessage?.({
      action: 'STEP_ID_SELECTED',
      stepId,
      fromJimo: true,
    });
  };

  useEffect(() => {
    handleSelectedStepId(selectedStepId);
  }, [selectedStepId]);

  useEffect(() => {
    if (template != null) {
      if (stepId != null) {
        setSelectedStepId(stepId);
      } else if (isTour) {
        setSelectedStepId(tourSteps[0].steps[0].uid);
      } else {
        setSelectedStepId(steps[0].uid);
      }
    } else {
      setSelectedStepId(null);
    }
  }, []);

  let actualSteps;

  if (isTour || isBanner || isHint) {
    tourSteps.forEach((ts) => {
      const [tourIndexOrder] = (ts.tourStepInfo || '0;0;0').split(';');
      ts.tourIndexOrder = parseInt(tourIndexOrder, 10);
      ts.steps.forEach((s) => {
        s.boostFlags = ts.boostFlags;
      });
    });
    tourSteps.sort((a, b) => a.tourIndexOrder - b.tourIndexOrder);
    actualSteps = tourSteps
      ?.map((t) => t.steps?.sort((a, b) => a.indexOrder - b.indexOrder))
      .flat()
      .filter((s) => s.removed !== true);
  } else {
    actualSteps =
      steps
        ?.sort((a, b) => a.indexOrder - b.indexOrder)
        ?.filter((s) => s.removed !== true) || [];
  }

  const handleDuplicate = async (evolutionId) => {
    amplitude.getInstance().logEvent(EVENT_DUPLICATE_EVOLUTION, {
      from: 'PushesTable',
    });
    const createdEvolution = await evolutionService.duplicateEvolution(
      evolutionId
    );
    if (
      [
        EVOLUTION_TYPE_TOUR,
        EVOLUTION_TYPE_SURVEY,
        EVOLUTION_TYPE_BANNER,
        EVOLUTION_TYPE_HINT,
      ].includes(createdEvolution.type)
    ) {
      history.push(
        ROUTE_POKE_BUILDER_FROM_TYPE(
          createdEvolution.uid,
          createdEvolution.type
        )
      );
    } else {
      history.push(ROUTE_BUILDER_CREATE({evolutionId: createdEvolution.uid}));
    }
  };

  const handleRemovePoke = async () => {
    setIsDeleting(true);
    try {
      await evolutionService.updateEvolution(template.uid, {
        boostFlags: 0,
      });
      toastSuccess('Poke deleted 👍', {toastId: 'poke-deleted'});
      setIsDeleting(false);
      setShowModalDeleteEvolution(false);
      setShowModalOptionalDeleteEvolution(false);
      onDelete(template.uid);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Deleting poke failed with error ', code);
      toastDanger([title, message], {actions});
      setIsDeleting(false);
      setShowModalDeleteEvolution(false);
      setShowModalOptionalDeleteEvolution(false);
    }
  };

  const handleDeleteEvolution = async () => {
    setIsDeleting(true);
    try {
      amplitude.getInstance().logEvent(EVENT_DELETE_EVOLUTION, {
        from: 'PushesTable',
      });
      await evolutionService.deleteEvolution(template.uid);
      toastSuccess('Experience deleted 👍', {toastId: 'evolution-deleted'});
      setIsDeleting(false);
      setShowModalDeleteEvolution(false);
      setShowModalOptionalDeleteEvolution(false);
      onDelete(template.uid);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Deleting experience failed with error ', code);
      toastDanger([title, message], {actions});
      setIsDeleting(false);
      setShowModalDeleteEvolution(false);
      setShowModalOptionalDeleteEvolution(false);
    }
  };

  const handleToggleState = async (live) => {
    const state = live === true ? EVOLUTION_STATE_LIVE : EVOLUTION_STATE_PAUSED;

    if (
      live === true &&
      hasFlag(F_EXTRA_CAN_PUBLISH, project.extraFlags) === false
    ) {
      return update({
        planId: PLAN_STARTUP_ID,
        title: 'Publish your experiences',
        description:
          'Unlock the ability to publish your experiences and reach your users with the Startup plan.',
      });
    }
    setIsTogglingState(true);
    try {
      if (live === true) {
        amplitude.getInstance().logEvent(EVENT_PLAY_POKE, {
          from: 'PushesTable',
        });
      } else {
        amplitude.getInstance().logEvent(EVENT_PAUSE_POKE, {
          from: 'PushesTable',
        });
      }
      await evolutionService.updateEvolution(template.uid, {
        state,
      });
      toastSuccess('Experience updated 👍', {toastId: 'poke-updated'});
      setIsTogglingState(false);
      onToggleState(template.uid, state);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Updating experience failed with error ', code);
      toastDanger([title, message], {actions});
      setIsTogglingState(false);
    }
  };

  const isLive =
    isDraft !== true && (state === EVOLUTION_STATE_LIVE || state == null);
  const isPaused = isDraft !== true && state === EVOLUTION_STATE_PAUSED;
  const isScheduled =
    isDraft !== true && dayjs(lastStepChangeAt).isAfter(dayjs());
  const isExpired =
    isDraft !== true && expiresAt != null && dayjs(expiresAt).isBefore(dayjs());
  const shownOnPortal = hasFlag(F_OPTION_SHOW_ON_PORTAL, optionsFlags);

  const isOldVersionPoke = hasFlag(F_OPTION_V2, optionsFlags) !== true;
  const isTemplate = template.uid == null;

  const canCreatePoke = [
    PROJECT_ROLE_ADMIN,
    PROJECT_ROLE_EDITOR,
    PROJECT_ROLE_MEMBER,
  ].includes(projectMember.role);

  return (
    <div className="preview-wrapper">
      <div className="steps-list-wrapper">
        {template.description && (
          <div className="infos">{template.description}</div>
        )}
        <div className="list-wrapper">
          {actualSteps.map((step, index) => {
            let icon, className, title;
            if (isTour) {
              icon = getIcon(getTypeFromBoostFlags(step.boostFlags));
            } else {
              ({icon, className, title} = getStepData(
                step.type || STEP_TYPE_TEXT_BLOCK
              ));
            }
            const {name} = step;

            return (
              <div className={classnames('block-wrapper')} key={index}>
                <Block
                  className={classnames(className, {})}
                  selected={selectedStepId === step.uid}
                  active={step?.prototypes?.some((p) =>
                    p?.steps?.some((ps) => ps.uid === selectedStepId)
                  )}
                  title={
                    isTemplate !== true &&
                    (isOldVersionPoke === true || shownOnPortal === true)
                      ? title
                      : name
                  }
                  icon={icon}
                  prototypes={step.prototypes}
                  onClick={() => {
                    setSelectedStepId(step.uid);
                    setSelectedConceptStepId(null);
                  }}>
                  {step.prototypes?.length > 0 && (
                    <div className="sub-blocks-wrapper">
                      {step.prototypes[0]?.steps?.map((prototypeStep) => {
                        const {title, icon, className} = getStepData(
                          prototypeStep.type
                        );

                        return (
                          <Block
                            className={classnames(className, 'sub-block')}
                            selected={
                              selectedConceptStepId === prototypeStep.uid
                            }
                            title={title}
                            icon={icon}
                            onClick={() => {
                              setSelectedStepId(step.uid);
                              setSelectedConceptStepId(prototypeStep.uid);
                            }}
                          />
                        );
                      })}
                    </div>
                  )}
                </Block>
              </div>
            );
          })}
        </div>
      </div>
      <div className="preview-group-wrapper">
        <div className="preview-group">
          <div className="main-wrapper">
            {isTemplate !== true &&
            (isOldVersionPoke === true || shownOnPortal === true) ? (
              <LivePreview
                boostedEvolution={template}
                previewRef={previewRef}
              />
            ) : (
              <LivePreviewNew
                poke={
                  tourSteps?.find((s) =>
                    s.steps.map((st) => st.uid).includes(selectedStepId)
                  ) || template
                }
                selectedStepId={selectedStepId}
                selectedConceptStepId={selectedConceptStepId}
                useDefaultTheme={useDefaultTheme}
              />
            )}
          </div>
          {withActions === true && canCreatePoke && (
            <div className={classnames('actions', {banner: isBanner})}>
              {isDraft !== true &&
                isExpired === false &&
                isScheduled === false && (
                  <Button
                    className={classnames('toggle-state-btn', {
                      resume: isPaused,
                      pause: isLive,
                    })}
                    primary
                    loading={isTogglingState}
                    iconLeft={
                      isLive ? 'icon-pause-rounded' : 'icon-play-rounded'
                    }
                    onClick={() => handleToggleState(isLive ? false : true)}>
                    {isLive ? 'Pause' : 'Play'}
                  </Button>
                )}
              {isOldVersionPoke === true && shownOnPortal !== true ? (
                <></>
              ) : (
                <>
                  <Button
                    primary
                    iconLeft="icon-edit-outline"
                    onClick={() => {
                      amplitude.getInstance().logEvent(EVENT_EDIT_EVOLUTION, {
                        from: 'PushesTable',
                      });
                      if (shownOnPortal) {
                        return history.push(
                          ROUTE_BUILDER_CREATE({evolutionId: template.uid})
                        );
                      } else {
                        history.push(
                          ROUTE_POKE_BUILDER_FROM_TYPE(
                            template.uid,
                            template.type
                          )
                        );
                      }
                    }}>
                    edit
                  </Button>
                  {shownOnPortal !== true && (
                    <Button
                      primary
                      iconLeft="icon-duplicate"
                      onClick={() => handleDuplicate(template.uid)}>
                      duplicate
                    </Button>
                  )}
                </>
              )}
              <Button
                danger
                iconOnly
                onClick={() => {
                  if (shownOnPortal === true) {
                    return setShowModalOptionalDeleteEvolution(true);
                  }
                  setShowModalDeleteEvolution(true);
                }}>
                <i className="icon-trash"></i>
              </Button>
            </div>
          )}
        </div>
        {onUseTemplateClick != null && (
          <div className="preview-footer">
            <Button
              className="use-template-btn"
              cta
              primary
              iconRight="icon-chevron-right"
              onClick={() => onUseTemplateClick()}>
              Use this template
            </Button>
          </div>
        )}
      </div>
      <Modal
        className="modal-optional-delete"
        isOpen={showModalOptionalDeleteEvolution}
        onRequestClose={() => setShowModalOptionalDeleteEvolution(false)}>
        <div className="content">
          This experience is linked to a post in your Changelog. Deleting your
          experience will only remove the in-app element displayed to your users
          but the post in your Changelog will remain.
        </div>
        <div className="action-btns">
          <Button
            muted
            onClick={() => setShowModalOptionalDeleteEvolution(false)}>
            Cancel
          </Button>
          <div className="delete-btns">
            <Button primary onClick={handleRemovePoke}>
              Delete
            </Button>
          </div>
        </div>
      </Modal>
      <ModalConfirm
        isOpen={showModalDeleteEvolution}
        isConfirming={isDeleting}
        confirmText="Yes, delete"
        onConfirm={handleDeleteEvolution}
        onCancel={() => setShowModalDeleteEvolution(false)}
      />
    </div>
  );
};

PreviewTemplate.propTypes = propTypes;
PreviewTemplate.defaultProps = defaultProps;

export default PreviewTemplate;
