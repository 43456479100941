import {useEffect} from 'react';
import {Swaler} from 'swaler';
import {Message, sendUndercityMessage} from './helper';

/**
 * List of actions
 */
const TARGET_ELEMENT_SELECT = 'TARGET_ELEMENT_SELECT';
const TARGET_ELEMENT_SELECTED = 'TARGET_ELEMENT_SELECTED';
const CURRENT_URL_CHANGE = 'CURRENT_URL_CHANGE';
const CURRENT_URL_REQUEST = 'CURRENT_URL_REQUEST';
const EMBEDDED_BUILDER_CLOSE = 'EMBEDDED_BUILDER_CLOSE';
const EVOLUTION_SAVED = 'EVOLUTION_SAVED';
const EVOLUTION_PREVIEW = 'EVOLUTION_PREVIEW';
const STEP_ID_SELECTED = 'STEP_ID_SELECTED';
const TOUR_STEP_ID_SELECTED = 'TOUR_STEP_ID_SELECTED';
const PREVIEW_START = 'PREVIEW_START';
const PREVIEW_STOP = 'PREVIEW_STOP';
const HOTSPOT_FORCE_VIEW = 'HOTSPOT_FORCE_VIEW';
const NAVIGATOR_SWITCH_TO = 'NAVIGATOR_SWITCH_TO';
const NAVIGATOR_GO_TO = 'NAVIGATOR_GO_TO';
const TARGET_NOT_FOUND = 'TARGET_NOT_FOUND';
const TARGET_FOUND = 'TARGET_FOUND';
const TOUR_STEP_HOVERED = 'TOUR_STEP_HOVERED';
const EXTENSION_POKE_SEND = 'EXTENSION_POKE_SEND';
const EXTENSION_PROJECT_SEND = 'EXTENSION_PROJECT_SEND';
const MODE_POSITION_PREVIEW_TOGGLE = 'MODE_POSITION_PREVIEW_TOGGLE';
const BUILDER_STATE_UPDATE = 'BUILDER_STATE_UPDATE';
const BLOCK_SELECTED = 'BLOCK_SELECTED';
const POKE_HEIGHT_CHANGE = 'POKE_HEIGHT_CHANGE';
const FOREGROUND_FORCE_UPDATE = 'FOREGROUND_FORCE_UPDATE';
const EVOLUTION_OVERLAY_UPDATE = 'EVOLUTION_OVERLAY_UPDATE';
const BUILDER_STATE_REQUEST = 'BUILDER_STATE_REQUEST';
const BUILDER_ABORT_ALL = 'BUILDER_ABORT_ALL';
const OBJECT_SAVED = 'OBJECT_SAVED';
const HIDE_EXPERIENCE_UPDATE = 'HIDE_EXPERIENCE_UPDATE';
const QUERY_SELECTOR_MANUAL_UPDATE = 'QUERY_SELECTOR_MANUAL_UPDATE';
const FOUND_ELEMENTS_COUNT = 'FOUND_ELEMENTS_COUNT';
const CONFIRM_ELEMENT_SELECTION = 'CONFIRM_ELEMENT_SELECTION';
const LANGUAGE_SELECTED = 'LANGUAGE_SELECTED';
const EVOLUTION_UPDATE = 'EVOLUTION_UPDATE';
const DALARAN_LOGIN_SUCCESS = 'DALARAN_LOGIN_SUCCESS';

const logger = new Swaler('useDalaranMessenger');

const useDalaranMessenger = ({
  onSelectedTargetElement = () => {},
  onCurrentUrlChange = () => {},
  onTargetNotFound = () => {},
  onTargetFound = () => {},
  onInteractivePreviewStopped = () => {},
  onSelectedTourStepId = () => {},
  onBlockSelected = () => {},
  onPokeHeightChange = () => {},
  onBuilderStateRequest = () => {},
  onStepSelected = () => {},
  onFoundElementsCount = () => {},
  onOpenPreviewSelector = () => {},
} = {}) => {
  const sendSelectTargetElement = (data) => {
    const message = new Message(TARGET_ELEMENT_SELECT, data);

    sendUndercityMessage(message);
  };

  const sendRequestCurrentUrl = () => {
    const message = new Message(CURRENT_URL_REQUEST);

    sendUndercityMessage(message);
  };

  const sendCloseEmbeddedBuilder = () => {
    const message = new Message(EMBEDDED_BUILDER_CLOSE);

    sendUndercityMessage(message);
  };

  const sendEvolutionSaved = ({evolution}) => {
    const message = new Message(EVOLUTION_SAVED, {evolution});

    sendUndercityMessage(message);
  };

  const sendPreviewEvolution = ({evolution}) => {
    const message = new Message(EVOLUTION_PREVIEW, {evolution});

    sendUndercityMessage(message);
  };

  const sendSelectedStepId = ({selectedStepId}) => {
    const message = new Message(STEP_ID_SELECTED, {stepId: selectedStepId});

    sendUndercityMessage(message);
  };

  const sendSelectedTourStepId = ({selectedTourStepId}) => {
    const message = new Message(TOUR_STEP_ID_SELECTED, {
      tourStepId: selectedTourStepId,
    });

    sendUndercityMessage(message);
  };

  const sendStartPreview = () => {
    const message = new Message(PREVIEW_START);
    sendUndercityMessage(message);
  };

  const sendForceHotspotView = (evolutionId, forceHotspotView) => {
    const message = new Message(HOTSPOT_FORCE_VIEW, {
      evolutionId,
      forceHotspotView,
    });
    sendUndercityMessage(message);
  };

  const sendSwitchToNavigatorMode = (previewAfterNavigation = false) => {
    const message = new Message(NAVIGATOR_SWITCH_TO, {
      previewAfterNavigation,
    });
    sendUndercityMessage(message);
  };

  const sendNavigateTo = ({url, previewAfterNavigation = false}) => {
    const message = new Message(NAVIGATOR_GO_TO, {
      url,
      previewAfterNavigation,
    });
    sendUndercityMessage(message);
  };
  const sendHoveredStep = ({stepId}) => {
    const message = new Message(TOUR_STEP_HOVERED, {
      stepId,
    });
    sendUndercityMessage(message);
  };
  const sendPokeToExtension = (data) => {
    const message = new Message(EXTENSION_POKE_SEND, data);
    sendUndercityMessage(message);
  };
  const sendProject = ({project}) => {
    const message = new Message(EXTENSION_PROJECT_SEND, {
      project,
    });
    sendUndercityMessage(message);
  };
  const sendTogglePositionPreviewMode = (data) => {
    const message = new Message(MODE_POSITION_PREVIEW_TOGGLE, data);
    sendUndercityMessage(message);
  };
  const sendBuilderState = (data) => {
    const message = new Message(BUILDER_STATE_UPDATE, data);
    sendUndercityMessage(message);
  };
  const sendInAppForegroundForce = (isForced) => {
    const message = new Message(FOREGROUND_FORCE_UPDATE, {isForced});
    sendUndercityMessage(message);
  };
  const sendEvolutionOverlayUpdate = (overlay) => {
    const message = new Message(EVOLUTION_OVERLAY_UPDATE, {overlay});
    sendUndercityMessage(message);
  };
  const sendAbortAll = () => {
    const message = new Message(BUILDER_ABORT_ALL);
    sendUndercityMessage(message);
  };
  const sendObjectSaved = (data) => {
    const message = new Message(OBJECT_SAVED, data);
    sendUndercityMessage(message);
  };
  const sendHideExperienceUpdate = (isForced) => {
    const message = new Message(HIDE_EXPERIENCE_UPDATE, {isForced});
    sendUndercityMessage(message);
  };
  const sendQuerySelectorManualUpdate = (data) => {
    const message = new Message(QUERY_SELECTOR_MANUAL_UPDATE, data);
    sendUndercityMessage(message);
  };
  const sendConfirmElementSelection = (data) => {
    const message = new Message(CONFIRM_ELEMENT_SELECTION, data);
    sendUndercityMessage(message);
  };
  const sendLanguageSelected = (language) => {
    const message = new Message(LANGUAGE_SELECTED, language);

    sendUndercityMessage(message);
  };
  const sendEvolutionUpdate = (data) => {
    const message = new Message(EVOLUTION_UPDATE, data);

    sendUndercityMessage(message);
  };
  const sendLoginSuccess = () => {
    const message = new Message(DALARAN_LOGIN_SUCCESS);

    sendUndercityMessage(message);
  };

  const recvHandler = (message) => {
    if (!message.data || !message.data.action) {
      return logger.debug(`Received miss-formatted message`);
    }
    const data = message.data.data;

    switch (message.data.action) {
      case TARGET_ELEMENT_SELECTED: {
        onSelectedTargetElement(data);
        break;
      }

      case CURRENT_URL_CHANGE: {
        onCurrentUrlChange(data);
        break;
      }

      case TARGET_NOT_FOUND: {
        onTargetNotFound(data);
        break;
      }

      case TARGET_FOUND: {
        onTargetFound();
        break;
      }

      case PREVIEW_STOP: {
        onInteractivePreviewStopped();
        break;
      }

      case TOUR_STEP_ID_SELECTED: {
        onSelectedTourStepId(data);
        break;
      }

      case BLOCK_SELECTED: {
        onBlockSelected(data);
        break;
      }

      case POKE_HEIGHT_CHANGE: {
        onPokeHeightChange(data);
        break;
      }

      case BUILDER_STATE_REQUEST: {
        onBuilderStateRequest(data);
        break;
      }

      case STEP_ID_SELECTED: {
        onStepSelected(data);
        break;
      }

      case FOUND_ELEMENTS_COUNT: {
        onFoundElementsCount(data);
        break;
      }

      case 'OPEN_PREVIEW_SELECTOR': {
        onOpenPreviewSelector();
        break;
      }

      default: {
        return logger.debug(`Received unknown action ${message.data.action}`);
      }
    }
    logger.debug(
      `Handled action ${message.data.action} with data ${JSON.stringify(data)}`
    );
  };

  useEffect(() => {
    window.addEventListener('message', recvHandler, false);

    return () => window.removeEventListener('message', recvHandler, false);
  }, []);

  return {
    sendSelectTargetElement,
    sendRequestCurrentUrl,
    sendCloseEmbeddedBuilder,
    sendEvolutionSaved,
    sendPreviewEvolution,
    sendSelectedStepId,
    sendSelectedTourStepId,
    sendStartPreview,
    sendForceHotspotView,
    sendSwitchToNavigatorMode,
    sendNavigateTo,
    sendHoveredStep,
    sendPokeToExtension,
    sendProject,
    sendTogglePositionPreviewMode,
    sendBuilderState,
    sendInAppForegroundForce,
    sendEvolutionOverlayUpdate,
    sendAbortAll,
    sendObjectSaved,
    sendHideExperienceUpdate,
    sendQuerySelectorManualUpdate,
    sendConfirmElementSelection,
    sendLanguageSelected,
    sendEvolutionUpdate,
    sendLoginSuccess,
  };
};

export default useDalaranMessenger;
