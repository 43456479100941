import {EVENT_CLICK_TEMPLATE} from 'amplitude';
import amplitude from 'amplitude-js';
import classnames from 'classnames';
import {func, string} from 'prop-types';
import {useState} from 'react';
import {
  EVOLUTION_TYPE_BANNER,
  EVOLUTION_TYPE_CHECKLIST,
  EVOLUTION_TYPE_HINT,
  EVOLUTION_TYPE_SURVEY,
  EVOLUTION_TYPE_TOUR,
} from 'services/evolution';
import './_Styles.scss';
import Sidebar from './components/Sidebar';
import {
  templates,
  TYPE_BANNER,
  TYPE_HINT,
  TYPE_HOTSPOT,
  TYPE_MODAL,
  TYPE_NAVIGATION,
  TYPE_SNIPPET,
  TYPE_TOOLTIP,
  TYPE_TOUR,
} from './templates';

export const getIcon = (type) => {
  if (type === TYPE_HOTSPOT) {
    return <i className="icon-slot-dot" />;
  } else if (type === TYPE_BANNER) {
    return <i className="icon-slot-top-bar" />;
  } else if (type === TYPE_SNIPPET) {
    return <i className="icon-slot-snippet" />;
  } else if (type === TYPE_MODAL) {
    return <i className="icon-slot-pop-in" />;
  } else if (type === TYPE_TOOLTIP) {
    return <i className="icon-slot-tooltip" />;
  } else if (type === TYPE_NAVIGATION) {
    return <i className="icon-forward" />;
  } else if (type === TYPE_TOUR) {
    return <i className="icon-tour" />;
  } else if (type === TYPE_HINT) {
    return <i className="isax isax-directbox-notif" />;
  }
};

export const TemplateBlock = ({
  id,
  image,
  experienceType,
  title,
  onClick,
  from,
}) => {
  let subtitle;

  if (experienceType === EVOLUTION_TYPE_TOUR) {
    subtitle = 'Tours & Modals';
  } else if (experienceType === EVOLUTION_TYPE_SURVEY) {
    subtitle = 'Surveys';
  } else if (experienceType === EVOLUTION_TYPE_BANNER) {
    subtitle = 'Banners';
  } else if (experienceType === EVOLUTION_TYPE_HINT) {
    subtitle = 'Hints';
  } else if (experienceType === EVOLUTION_TYPE_CHECKLIST) {
    subtitle = 'Checklists';
  }

  return (
    <div
      key={id}
      className={classnames('template-block')}
      onClick={() => {
        amplitude.getInstance().logEvent(EVENT_CLICK_TEMPLATE, {
          template: title,
          type: experienceType,
          from: from,
        });
        onClick();
      }}>
      <div className="template-header">
        <div className="img-wrapper">
          <img src={image} alt="template" />
        </div>
      </div>
      <div className="content">
        <div className="title subtitle-4">{title}</div>
        <div className="subtitle body-4 n-700">{subtitle}</div>
      </div>
    </div>
  );
};

const propTypes = {
  type: string.isRequired,
  onClickTemplate: func.isRequired,
  onClickStartFromScratch: func.isRequired,
};

const defaultProps = {};

const TemplatesModal = ({type, onClickTemplate, onClickStartFromScratch}) => {
  const [selectedType, setSelectedType] = useState(type);
  const [selectedUseCase, setSelectedUseCase] = useState(null);

  const filteredTemplates = templates().filter((t) => {
    if (selectedType != null && t.experienceType !== selectedType) {
      return false;
    }

    if (selectedUseCase != null && t.tags.includes(selectedUseCase) === false) {
      return false;
    }

    return true;
  });

  let title, subtitle, icon;

  if (selectedType === EVOLUTION_TYPE_TOUR) {
    title = 'Tours';
    subtitle = 'Show your users around';
    icon = 'isax isax-routing-2';
  } else if (selectedType === EVOLUTION_TYPE_SURVEY) {
    title = 'Surveys';
    subtitle = 'Collect feedback from your users';
    icon = 'isax isax-note-2';
  } else if (selectedType === EVOLUTION_TYPE_BANNER) {
    title = 'Banners';
    subtitle = 'Display important information';
    icon = 'icon-slot-top-bar';
  } else if (selectedType === EVOLUTION_TYPE_HINT) {
    title = 'Hints';
    subtitle = 'Highlight important elements';
    icon = 'icon-slot-dot';
  } else if (selectedUseCase != null) {
    title = selectedUseCase;
    subtitle = `All ${selectedUseCase.toLowerCase()} templates`;
  } else {
    title = 'All templates';
    subtitle = 'All templates';
  }

  return (
    <div className="templates-modal-wrapper">
      <Sidebar
        type={selectedType}
        useCase={selectedUseCase}
        onChange={({type = null, useCase = null}) => {
          setSelectedType(type);
          setSelectedUseCase(useCase);
        }}
      />
      <div className="template-picker-wrapper">
        <div className="template-picker-header">
          <div className="title title-4">{title}</div>
          <div className="subtitle body-3 n-500">{subtitle}</div>
        </div>
        <div className="templates-wrapper">
          {selectedType != null && (
            <div
              className="start-from-scratch"
              onClick={() => onClickStartFromScratch(selectedType)}>
              <img
                src="https://assets.usejimo.com/templates/start-from-scratch.svg"
                alt="start-from-scratch"
              />
              <div className="icon-wrapper">
                <i className={icon} />{' '}
                <div className="icon-plus-wrapper">
                  <i className="icon-plus" />
                </div>
              </div>
              <div className="text-wrapper">
                <div className="title subtitle-3 n-800">
                  New {title.slice(0, -1)}
                </div>
                <div className="subtitle body-4 n-600">Start from scratch</div>
              </div>
            </div>
          )}
          {filteredTemplates.map((template) => (
            <TemplateBlock
              {...template}
              onClick={() => onClickTemplate(template)}
              from="templates_modal"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

TemplatesModal.propTypes = propTypes;
TemplatesModal.defaultProps = defaultProps;

export default TemplatesModal;
