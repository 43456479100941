import classnames from 'classnames';
import './_styles.scss';

export const InteractiveOption = ({
  icon,
  iconComponent,
  title,
  description,
  active,
  disabled,
  onPreview,
  children,
  onClick,
  withRadioBox,
  withCheckBox,
  iconBoxPosition = 'right',
  onToggleClick = () => {},
  ...rest
}) => {
  const classNames = classnames(
    rest.className,
    'builder-channel-interactive-option',
    {
      'is-active': active === true,
      'is-disabled': disabled === true,
      'has-children': children != null,
    }
  );

  const IconBox = () => (
    <>
      {withRadioBox === true && active && (
        <i className="icon-radiobox" onClick={onToggleClick} />
      )}
      {withRadioBox === true && active === false && (
        <i className="icon-radiobox-o" onClick={onToggleClick} />
      )}
      {withCheckBox === true && active && (
        <i className="icon-checkbox" onClick={onToggleClick} />
      )}
      {withCheckBox === true && active === false && (
        <i className="icon-checkbox-o" onClick={onToggleClick} />
      )}
    </>
  );

  return (
    <div {...rest} className={classNames}>
      <div
        className="option-main-wrapper"
        onClick={() => {
          if (disabled !== true) {
            onClick();
          }
        }}
      >
        {iconBoxPosition === 'left' && <IconBox />}
        <div className="icon-wrapper">
          {iconComponent ? (
            <>{iconComponent}</>
          ) : (
            <i className={classnames(icon, 'icon')} />
          )}
        </div>
        <div className="title-description-wrapper">
          {title}
          <small>{description}</small>
          {onPreview != null && (
            <div
              className="trigger-preview"
              onClick={(e) => {
                e.stopPropagation();
                onPreview();
              }}
            >
              <i className="icon-eye preview-icon"></i>
            </div>
          )}
        </div>
        {iconBoxPosition === 'right' && <IconBox />}
      </div>
      {children != null && (
        <div className="option-children-wrapper">{children}</div>
      )}
    </div>
  );
};
