import classNames from 'classnames';
import Button from 'components/Button';
import Label from 'components/Label';
import LivePreviewNew from 'components/LivePreviewNew';
import Tooltip from 'components/Tooltip';
import dayjs from 'dayjs';
import {BLOCKS, getTypeFromBoostFlags} from 'helpers/poke';
import {string} from 'prop-types';
import React from 'react';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import {ROUTE_POKE_BUILDER_FROM_TYPE} from 'router/routes.const';
import {
  HINT_TYPE_BUTTON,
  HINT_TYPE_HIDDEN,
  HINT_TYPE_ICON,
  HINT_TYPE_LABEL,
} from 'scenes/PokeBuilder/components/BlockEditor/blocks/Hint';
import {getIcon} from 'scenes/Pushes/components/ModalCreatePoke/components/TemplatesModal';
import {
  TYPE_BANNER,
  TYPE_HINT,
  TYPE_HOTSPOT,
  TYPE_MODAL,
  TYPE_NAVIGATION,
  TYPE_SNIPPET,
  TYPE_TOOLTIP,
  TYPE_TOUR,
} from 'scenes/Pushes/components/ModalCreatePoke/components/TemplatesModal/templates';
import {
  EVOLUTION_TYPE_BANNER,
  EVOLUTION_TYPE_CHECKLIST,
  EVOLUTION_TYPE_HINT,
  EVOLUTION_TYPE_SURVEY,
  EVOLUTION_TYPE_TOUR,
} from 'services/evolution';
import {
  BLOCK_TYPE_HINT,
  STEP_TYPE_CHECKLIST,
  STEP_TYPE_SUCCESS,
} from 'services/steps';
import {BLOCK_CONCEPT} from 'shared/front/components/Poke/constants/blocks';
//
import {EmptyStateBlock, EmptyStateImgs} from 'components/EmptyStateImgs';
import PokeBlock from 'components/PokeBlock';
import './_Styles.scss';

export const getTypeNameAndIcon = (poke) => {
  let typeName, icon;
  const type = getTypeFromBoostFlags(poke.boostFlags);
  icon = getIcon(type);
  switch (type) {
    case TYPE_HOTSPOT:
      typeName = 'Hotspot';
      break;
    case TYPE_SNIPPET:
      typeName = 'Snippet';
      break;
    case TYPE_MODAL:
      typeName = 'Modal';
      break;
    case TYPE_BANNER:
      typeName = 'Banner';
      break;
    case TYPE_TOUR:
      typeName = 'Tour';
      break;
    case TYPE_TOOLTIP:
      typeName = 'Tooltip';
      break;
    case TYPE_NAVIGATION:
      typeName = 'Navigation';
      break;
    case TYPE_HINT: {
      const step = poke.steps[0];
      const hintBlock = step.blocks.find((b) => b.type === BLOCK_TYPE_HINT);
      if (hintBlock?.style?.type === HINT_TYPE_ICON) {
        typeName = 'Icon';
        icon = <i className="icon-question-circle" />;
      } else if (hintBlock?.style?.type === HINT_TYPE_LABEL) {
        typeName = 'Label';
        icon = <i className="isax isax-tag5" />;
      } else if (hintBlock?.style?.type === HINT_TYPE_HIDDEN) {
        typeName = 'Targeted element';
        icon = <i className="icon-target" />;
      } else if (hintBlock?.style?.type === HINT_TYPE_BUTTON) {
        typeName = 'Button';
        icon = <i className="icon-label-icon" />;
      } else {
        typeName = 'Hint';
      }
      break;
    }
    default:
      typeName = '';
      break;
  }
  return {typeName, icon};
};

export const HiddenStepTooltip = ({step, isHint, trigger}) => {
  return (
    <Tooltip dark offsetY={4} className="tooltip-hidden-step" trigger={trigger}>
      <span className="body-3">
        This {isHint ? 'hint' : 'step'} was hidden for users.
      </span>
      <span className="body-3">
        Some users might have not seen this {isHint ? 'hint' : 'step'}.
      </span>
      <span className="body-3 n-600">
        Hidden since {dayjs(step.updateData?.date).format('DD MMM YYYY')}
      </span>
    </Tooltip>
  );
};

const OverviewItem = ({
  evolution,
  type: pokeType,
  index,
  poke,
  step,
  onEdit,
}) => {
  let icon, typeName;

  const isTour = pokeType === EVOLUTION_TYPE_TOUR;
  const isBanner = pokeType === EVOLUTION_TYPE_BANNER;
  const isHint = pokeType === EVOLUTION_TYPE_HINT;
  const isSurvey = pokeType === EVOLUTION_TYPE_SURVEY;
  const isChecklist = pokeType === EVOLUTION_TYPE_CHECKLIST;

  if (isTour || isBanner || isHint) {
    const typeNameAndIcon = getTypeNameAndIcon(poke);
    typeName = typeNameAndIcon.typeName;
    icon = typeNameAndIcon.icon;
  } else if (isSurvey) {
    const block = BLOCKS.find((b) => b.step === step.type);
    typeName = block?.title;
    icon = block?.icon;
  } else if (isChecklist) {
    if (step.type === STEP_TYPE_CHECKLIST) {
      typeName = 'Checklist';
      icon = <i className="isax isax-task-square5" />;
    } else if (step.type === STEP_TYPE_SUCCESS) {
      typeName = 'Success';
      icon = <i className="isax isax-heart5" />;
    }
  }

  return (
    <div className="overview-item">
      <div className="step-preview">
        <LivePreviewNew
          evolution={evolution}
          poke={poke}
          selectedStepId={step.uid}
          isScaledDownPreview
          scale={0.8}
        />
        <Button
          className="btn-edit"
          thin
          iconLeft="icon-edit-outline"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();

            onEdit();
          }}>
          Edit{' '}
        </Button>
        {step.removed === true && (
          <HiddenStepTooltip
            step={step}
            trigger={
              <div className="label-hidden-wrapper">
                <Label
                  size="small"
                  type="neutral"
                  secondary
                  iconLeft="isax isax-eye-slash"
                  className="label-hidden">
                  Hidden
                </Label>
              </div>
            }
          />
        )}
      </div>
      <div className="step-info">
        <div className="step-group">
          {isChecklist !== true && (
            <div className="step-index">
              {isHint ? 'Hint' : 'Step'} {index + 1}
            </div>
          )}
          <div className="step-name">
            {isChecklist === true ? typeName : step.name}
          </div>
        </div>
        <div className="step-type">
          {icon}
          {typeName}
        </div>
      </div>
    </div>
  );
};

const propTypes = {
  evolution: string.isRequired,
};

const defaultProps = {};

const Overview = ({evolution}) => {
  const history = useHistory();

  const {tourSteps, steps, type} = evolution;

  tourSteps?.forEach((ts) => {
    const [tourIndexOrder] = (ts.tourStepInfo || '0;0;0').split(';');
    ts.tourIndexOrder = parseInt(tourIndexOrder, 10);
    ts.steps.forEach((s) => {
      s.boostFlags = ts.boostFlags;
    });
  });
  tourSteps?.sort((a, b) => a.tourIndexOrder - b.tourIndexOrder);

  const allStepsWithPoke =
    tourSteps?.length > 0
      ? tourSteps.reduce((steps, tourStep) => {
          if (tourStep.steps?.length > 0) {
            tourStep.steps.sort((a, b) => a.indexOrder - b.indexOrder);
            steps = steps.concat(
              tourStep.steps
                .filter((step) => !step.removed)
                .map((step) => ({step, poke: tourStep}))
            );
          }
          return steps;
        }, [])
      : steps
          .filter((step) => step.fromPrototypeStep == null)
          .filter((step) => !step.removed)
          .map((step) => {
            const hasConceptBlock = step.blocks.some(
              (b) => b.type === BLOCK_CONCEPT
            );

            if (hasConceptBlock === true) {
              const conceptSteps = steps.filter(
                (s) =>
                  s.fromPrototypeStep != null &&
                  s.fromPrototypeStep.uid === step.uid
              );

              return [{step, poke: evolution}].concat(
                conceptSteps
                  .sort((a, b) => a.indexOrder - b.indexOrder)
                  .map((s) => ({step: s, poke: evolution}))
              );
            }
            return {step, poke: evolution};
          })
          .flat();

  const isBanner = type === EVOLUTION_TYPE_BANNER;

  const isChecklist = type === EVOLUTION_TYPE_CHECKLIST;

  return (
    <PokeBlock className="poke-overview">
      <div
        className={classNames('overview-content', {
          'is-banner': isBanner,
          'is-checklist': isChecklist,
        })}>
        {allStepsWithPoke.length <= 0 ? (
          <div className="empty-state-wrapper">
            <EmptyStateBlock
              img={EmptyStateImgs.EmptyUsersReached}
              title="No steps added yet"
              description="Start building your in-app experience by adding steps"
            />
            <Button
              primary
              iconLeft="icon-plus"
              onClick={() =>
                history.push(
                  ROUTE_POKE_BUILDER_FROM_TYPE(evolution.uid, evolution.type)
                )
              }>
              Add step
            </Button>
          </div>
        ) : (
          allStepsWithPoke.map(({step, poke}, index) => (
            <OverviewItem
              evolution={evolution}
              key={index}
              type={type}
              index={index}
              poke={poke}
              step={step}
              onEdit={() => {
                history.push(
                  ROUTE_POKE_BUILDER_FROM_TYPE(evolution.uid, evolution.type, {
                    stepId: step.uid,
                  })
                );
              }}
            />
          ))
        )}
      </div>
    </PokeBlock>
  );
};

Overview.propTypes = propTypes;
Overview.defaultProps = defaultProps;

export default Overview;
