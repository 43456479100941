import classNames from 'classnames';
import DefaultLoader from 'components/Loader';
import ModalLaunchBuilder from 'components/ModalLaunchBuilder';
import {GlobalContext} from 'contextes/Global';
import {sendSetParentTab} from 'helpers/utils';
import useAcrossTabs from 'hooks/UseAcrossTabs';
import {useInAppBuilder} from 'hooks/useInAppBuilder';
import useScrollDetector from 'hooks/UseScrollDetector';
import {createContext, useContext, useRef, useState} from 'react';
import {useQuery} from 'react-query';
import {useRouteMatch} from 'react-router-dom';
import {InAppBuilderContext} from 'scenes/Pushes/context';
import {trackerService} from 'services';
import {
  TRACKER_TYPE_ACTIVATION_TRACKER,
  TRACKER_TYPE_FEATURE_USAGE,
} from 'services/tracker';
import './_Styles.scss';
import ActivationStats from './components/ActivationStats';
import CompletionRate from './components/CompletionRate';
import FeatureUsageChart from './components/FeatureUsageChart';
import FeatureUsageChartUsers from './components/FeatureUsageChartUsers';
import FeatureUsageStats from './components/FeatureUsageStats';
import MilestoneOverview from './components/MilestoneOverview';
import TrackerHeader from './components/TrackerHeader';
import TrackerUsers from './components/TrackerUsers';

export const TrackerContext = createContext();

const ActivationTracker = () => {
  return (
    <>
      <ActivationStats />
      <MilestoneOverview />
      <CompletionRate />
      <TrackerUsers />
    </>
  );
};

const FeatureUsageTracker = () => {
  return (
    <>
      <FeatureUsageStats />
      <FeatureUsageChart />
      <FeatureUsageChartUsers />
      <TrackerUsers />
    </>
  );
};

const Tracker = () => {
  const match = useRouteMatch();
  const trackerId = match.params.trackerId;

  const contentRef = useRef();

  const {
    isLoading,
    data: tracker,
    refetch,
  } = useQuery({
    queryKey: ['tracker', trackerId],
    queryFn: () => trackerService.getTracker({trackerId}),
    refetchOnWindowFocus: false,
  });

  const isScrolled = useScrollDetector(contentRef);

  const [segments, setSegments] = useState([]);
  const [onTheFlySegment, setOnTheFlySegment] = useState(null);

  const {parentTabRef, childTabIdRef, handshakeDataRef} =
    useContext(GlobalContext);

  const {openNewTab} = useAcrossTabs({
    onHandshakeCallback: () => {
      sendSetParentTab();

      parentTabRef.current?.broadCastTo(
        childTabIdRef.current,
        handshakeDataRef.current
      );
    },
  });

  const {
    openInAppBuilderAt,
    isEditingInApp,
    launchBuilderModalData,
    closeLaunchBuilderModal,
    goToEditInApp,
    stopInAppEditing,
  } = useInAppBuilder({openNewTab});

  return (
    <TrackerContext.Provider
      value={{
        tracker,
        segments,
        setSegments,
        onTheFlySegment,
        setOnTheFlySegment,
        refetch,
      }}>
      <InAppBuilderContext.Provider
        value={{
          openInAppBuilderAt,
          isEditingInApp,
          launchBuilderModalData,
          closeLaunchBuilderModal,
          goToEditInApp,
          stopInAppEditing,
        }}>
        <div
          className={classNames('s-tracker', 'fade-in', {
            'is-loading': isLoading === true,
          })}>
          {isLoading === true || tracker == null ? null : (
            <TrackerHeader scrolled={isScrolled} />
          )}
          <div className="tracker-content-wrapper" ref={contentRef}>
            <div className="tracker-content">
              {tracker == null && isLoading !== true ? (
                <div className="tracker-not-found">Tracker not found</div>
              ) : isLoading === true ? (
                <div className="loader-wrapper">
                  <DefaultLoader />
                </div>
              ) : (
                <>
                  {tracker.type === TRACKER_TYPE_ACTIVATION_TRACKER && (
                    <ActivationTracker />
                  )}
                  {tracker.type === TRACKER_TYPE_FEATURE_USAGE && (
                    <FeatureUsageTracker />
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        {launchBuilderModalData != null && (
          <ModalLaunchBuilder
            isOpen={launchBuilderModalData != null}
            onRequestClose={() => {
              closeLaunchBuilderModal();
            }}
            onOpenUrl={(url) => openInAppBuilderAt(url)}
            data={launchBuilderModalData}
          />
        )}
      </InAppBuilderContext.Provider>
    </TrackerContext.Provider>
  );
};

export default Tracker;
