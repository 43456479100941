import data from '@emoji-mart/data';
import Loader from 'components/Loader';
import ModalLaunchBuilder from 'components/ModalLaunchBuilder';
import {toastDanger} from 'components/Toaster';
import {GlobalContext} from 'contextes/Global';
import {errorHelpers} from 'helpers';
import {sendSetParentTab} from 'helpers/utils';
import useAcrossTabs from 'hooks/UseAcrossTabs';
import {useInAppBuilder} from 'hooks/useInAppBuilder';
import {createContext, useContext, useEffect, useRef, useState} from 'react';
import {useQuery} from 'react-query';
import {useParams} from 'react-router-dom';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import {ROUTE_BUILDER_TRACKER} from 'router/routes.const';
import {InAppBuilderContext} from 'scenes/Pushes/context';
import {eventService} from 'services';
import {EVENT_CONDITION_TYPE_DELAY, EVENT_SOURCE_JIMO} from 'services/event';
import {Swaler} from 'swaler';
import './_Styles.scss';
import {CardCreateTracker} from './components/CardCreateTracker';
import EventConditions from './components/EventConditions';
import {buildLogic, isEventValid} from './components/EventConditions/utils';
import EventDetails from './components/EventDetails';
import EventHeader from './components/EventHeader';

const logger = new Swaler('Event');

const defaultEvent = {
  name: '',
  description: '',
  conditions: [],
};

export const getRandomEmoji = () => {
  const emojis = data.emojis;
  const emojisArr = Object.keys(emojis);
  const objectEmojisArr = data.categories.find(
    (category) => category.id === 'objects'
  ).emojis;
  const randomIndex = Math.floor(Math.random() * objectEmojisArr.length);
  const foundEmoji = objectEmojisArr[randomIndex];
  const emojiIndex = emojisArr.findIndex((emoji) => emoji === foundEmoji);
  return emojis[emojisArr[emojiIndex]]?.skins?.[0]?.native;
};

export const EventContext = createContext();

const Event = () => {
  const history = useHistory();
  const {eventId} = useParams();

  const {parentTabRef, childTabIdRef, handshakeDataRef} =
    useContext(GlobalContext);

  const [event, setEvent] = useState(defaultEvent);
  const [name, setName] = useState(null);
  const [codeName, setCodeName] = useState(null);
  const [description, setDescription] = useState(null);
  const [icon, setIcon] = useState(eventId === 'new' ? getRandomEmoji() : null);
  const [conditions, setConditions] = useState([]);
  const [logic, setLogic] = useState(buildLogic());

  const [isCodeNameManuallyChanged, setIsCodeNameManuallyChanged] =
    useState(false);
  const [isCodeNameAlreadyUsed, setIsCodeNameAlreadyUsed] = useState(false);

  const timerId = useRef();

  useEffect(() => {
    if (isCodeNameManuallyChanged || eventId !== 'new') {
      return;
    }

    setCodeName(name?.toLowerCase().replace(/\s/g, '_'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  // debounce check for codeName events that already exist
  useEffect(() => {
    if (isCodeNameManuallyChanged && !codeName) {
      setIsCodeNameManuallyChanged(false);
    }

    if (eventId !== 'new' || !codeName) {
      setIsCodeNameAlreadyUsed(false);
      return;
    }

    const checkCodeName = async () => {
      const events = await eventService.getEvents({
        codeName,
        sources: [EVENT_SOURCE_JIMO],
      });
      if (events.length > 0) {
        setIsCodeNameAlreadyUsed(true);
      } else {
        setIsCodeNameAlreadyUsed(false);
      }
    };

    // Clear previous timeout
    clearTimeout(timerId.current);

    // Set a new timeout
    timerId.current = setTimeout(() => {
      checkCodeName();
    }, 300);

    // Clear the timeout when the component unmounts
    return () => clearTimeout(timerId.current);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codeName]);

  const {isLoading, refetch: refetchEvent} = useQuery({
    queryKey: ['segment', eventId],
    queryFn: async () => {
      const event = await eventService.getEventById(eventId, {
        relations: ['conditions'],
      });
      setEvent(event);
      setName(event.name);
      setCodeName(event.codeName);
      setDescription(event.description);
      setIcon(event.icon);
      setConditions(event.conditions || []);
      setLogic(JSON.parse(JSON.stringify(buildLogic(event))));

      return event;
    },
    onError: (err) => {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Fetching event failed with error ', code);
      toastDanger([title, message], {actions});
    },
    refetchOnWindowFocus: false,
    enabled: eventId != null && eventId !== 'new',
  });

  const hasChanges = () =>
    JSON.stringify(event.conditions) !== JSON.stringify(conditions);

  const {isValid} = isEventValid(conditions, logic);

  const {openNewTab} = useAcrossTabs({
    onHandshakeCallback: () => {
      sendSetParentTab();

      parentTabRef.current?.broadCastTo(
        childTabIdRef.current,
        handshakeDataRef.current
      );
    },
  });

  const {
    openInAppBuilderAt,
    isEditingInApp,
    launchBuilderModalData,
    closeLaunchBuilderModal,
    goToEditInApp,
    stopInAppEditing,
  } = useInAppBuilder({openNewTab});

  return (
    <EventContext.Provider
      value={{
        event,
        hasChanges,
        name,
        setName,
        codeName,
        setCodeName,
        description,
        setDescription,
        icon,
        setIcon,
        conditions,
        setConditions,
        logic,
        setLogic,
        refetchEvent,
        isValid,
        setIsCodeNameManuallyChanged,
        isCodeNameAlreadyUsed,
      }}>
      <InAppBuilderContext.Provider
        value={{
          openInAppBuilderAt,
          isEditingInApp,
          launchBuilderModalData,
          closeLaunchBuilderModal,
          goToEditInApp,
          stopInAppEditing,
        }}>
        <div className="s-event">
          {isLoading === true || event == null ? (
            <div className="loader-wrapper">
              <Loader width="40px" />
            </div>
          ) : (
            <>
              <div className="event-header-wrapper">
                <EventHeader />
              </div>
              <div className="event-content-wrapper">
                {eventId !== 'new' && (
                  <CardCreateTracker
                    onTrackClick={() => {
                      history.push(
                        ROUTE_BUILDER_TRACKER('new-feature-usage-tracker', {
                          eventId: event.uid,
                        })
                      );
                    }}
                  />
                )}
                <EventDetails />
                <EventConditions
                  conditions={conditions}
                  setConditions={setConditions}
                  logic={logic}
                  setLogic={setLogic}
                  omitTypes={[EVENT_CONDITION_TYPE_DELAY]}
                />
              </div>
            </>
          )}
        </div>

        {launchBuilderModalData != null && (
          <ModalLaunchBuilder
            isOpen={launchBuilderModalData != null}
            onRequestClose={() => {
              closeLaunchBuilderModal();
            }}
            onOpenUrl={(url) => openInAppBuilderAt(url)}
            data={launchBuilderModalData}
          />
        )}
      </InAppBuilderContext.Provider>
    </EventContext.Provider>
  );
};

export default Event;
