import classNames from 'classnames';
import Divider from 'components/Divider';
import Tabs from 'components/Tabs';
import {BuilderContext} from 'contextes/builder';
import Lottie from 'lottie-react';
import React, {useContext, useState} from 'react';
import {useSelector} from 'react-redux';
import {
  TYPE_HOTSPOT,
  TYPE_MODAL,
  TYPE_SNIPPET,
  TYPE_TOOLTIP,
} from 'scenes/Pushes/components/ModalCreatePoke/components/TemplatesModal/templates';
import {generalSelector} from 'selectors';
import {EVOLUTION_TYPE_SURVEY, EVOLUTION_TYPE_TOUR} from 'services/evolution';
import {
  STEP_TYPE_CONCEPT_TEST,
  STEP_TYPE_INTERVIEW,
  STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
  STEP_TYPE_NPS,
  STEP_TYPE_OPINION_SCALE,
  STEP_TYPE_SLIDER,
  STEP_TYPE_SUCCESS,
  STEP_TYPE_TEXT_BLOCK,
  STEP_TYPE_TEXT_LONG,
} from 'services/steps';
import {presets as presetsGroups} from '../PresetSelector/presets';
import {applyThemeToEvolution} from '../ThemeManager/utils';
import './_Styles.scss';
import drag from './animations/drag.json';
import Choice from './imgs/survey/choice.svg';
import Concept from './imgs/survey/concept.svg';
import Interview from './imgs/survey/interview.svg';
import Nps from './imgs/survey/nps.svg';
import Open from './imgs/survey/open.svg';
import Scale from './imgs/survey/scale.svg';
import Slider from './imgs/survey/slider.svg';
import Say from './imgs/survey/text.svg';
import ThankYou from './imgs/survey/ty.svg';

const Hotspot = 'https://assets.usejimo.com/content-manager/hotspot.svg';
const Tooltip = 'https://assets.usejimo.com/content-manager/tooltip.svg';
const Modal = 'https://assets.usejimo.com/content-manager/modal.svg';
const Snippet = 'https://assets.usejimo.com/content-manager/modal.svg';

const propTypes = {};

const SurveyBlocks = [
  {
    title: 'NPS',
    image: Nps,
    type: STEP_TYPE_NPS,
    category: 'Ask',
  },
  {
    title: 'Multiple Choice',
    image: Choice,
    type: STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
    category: 'Ask',
  },
  {
    title: 'Slider',
    image: Slider,
    type: STEP_TYPE_SLIDER,
    category: 'Ask',
  },
  {
    title: 'Opinion Scale',
    image: Scale,
    type: STEP_TYPE_OPINION_SCALE,
    category: 'Ask',
  },
  {
    title: 'Open Question',
    image: Open,
    type: STEP_TYPE_TEXT_LONG,
    category: 'Ask',
  },
  {
    title: 'Concept Test',
    image: Concept,
    type: STEP_TYPE_CONCEPT_TEST,
    category: 'Test',
  },
  {
    title: 'Interview Prompt',
    image: Interview,
    type: STEP_TYPE_INTERVIEW,
    category: 'Test',
  },
  {
    title: 'Text Block',
    image: Say,
    type: STEP_TYPE_TEXT_BLOCK,
    category: 'Say',
  },
  {
    title: 'Thank You',
    image: ThankYou,
    type: STEP_TYPE_SUCCESS,
    category: 'Say',
  },
];

const TAB_ALL = 'All';
const TAB_MODAL = 'Modal';
const TAB_TOOLTIP = 'Tooltip';
const TAB_HOTSPOT = 'Hotspot';
const TAB_SNIPPET = 'Snippet';

export const StepSectionItem = ({
  title,
  description,
  icon,
  image,
  data,
  onClick,
  isSurvey,
}) => {
  const {setIsDraggingToAddStep} = useContext(BuilderContext);

  const onDragStart = (event) => {
    event.dataTransfer.setData('application/reactflow', JSON.stringify(data));
    event.dataTransfer.effectAllowed = 'move';
    setIsDraggingToAddStep(true);
  };

  const onDragEnd = (event) => {
    setIsDraggingToAddStep(false);
  };

  if (isSurvey) {
    return (
      <div className={classNames('section-item-survey')}>
        <div className="draggable-wrapper">
          <div
            className="image-wrapper"
            draggable
            onDragStart={onClick != null ? null : onDragStart}
            onDragEnd={onClick != null ? null : onDragEnd}
            onClick={onClick || (() => {})}>
            <img src={image} alt="img" />
          </div>
        </div>
        <div className="title subtitle-4 n-800">{title}</div>
      </div>
    );
  }

  return (
    <div
      className={classNames('draggable-wrapper')}
      draggable
      onDragStart={onClick != null ? null : onDragStart}
      onDragEnd={onClick != null ? null : onDragEnd}
      onClick={onClick || (() => {})}>
      <div className={classNames('section-item')}>
        <div className="header">
          {icon && (
            <div className="icon-wrapper">
              <i className={icon} />
            </div>
          )}
          <div className="content">
            <div className="title subtitle-3 n-800">{title}</div>
            {description && (
              <div className="description body-4 n-700">{description}</div>
            )}
          </div>
        </div>
        {image && (
          <div className="image-wrapper">
            <img src={image} alt="img" />
          </div>
        )}
      </div>
    </div>
  );
};

const ContentManager = () => {
  const {evolution} = useContext(BuilderContext);

  const project = useSelector((state) => generalSelector.getProject(state));

  const [tab, setTab] = useState(TAB_ALL);

  const isTour = evolution.type === EVOLUTION_TYPE_TOUR;
  const isSurvey = evolution.type === EVOLUTION_TYPE_SURVEY;

  const presetsExperiences = presetsGroups.filter((p) => {
    if (tab === TAB_ALL) {
      return [TYPE_TOOLTIP, TYPE_HOTSPOT, TYPE_MODAL, TYPE_SNIPPET].includes(
        p.type
      );
    } else if (tab === TAB_TOOLTIP) {
      return p.type === TYPE_TOOLTIP;
    } else if (tab === TAB_HOTSPOT) {
      return p.type === TYPE_HOTSPOT;
    } else if (tab === TAB_MODAL) {
      return p.type === TYPE_MODAL;
    } else if (tab === TAB_SNIPPET) {
      return p.type === TYPE_SNIPPET;
    } else {
      return false;
    }
  });

  let styledExperiences = presetsExperiences;

  if (evolution.theme != null || project.defaultTheme != null) {
    styledExperiences = presetsExperiences.map((p) =>
      applyThemeToEvolution(p, evolution.theme || project.defaultTheme)
    );
  }

  return (
    <div className="content-manager-wrapper">
      <div className="drag-and-drop-help n-700 body-4">
        <Lottie
          animationData={drag}
          className={classNames('animation-drag')}
          loop={true}
        />
        Drag & drop to add a step
      </div>
      <Divider />
      <div
        className={classNames('content-manager', {
          'is-survey': isSurvey,
        })}>
        {isTour && (
          <div className="content-groups">
            <div className="group">
              <div className="content-manager-header">
                <div className="content-manager-title">Start from scratch</div>
              </div>
              <div className="sections">
                <div className="section-list">
                  <StepSectionItem
                    title="Modal"
                    icon="isax isax-copy"
                    image={Modal}
                    data={{type: TYPE_MODAL}}
                  />
                  <StepSectionItem
                    title="Snippet"
                    icon="isax isax-mouse-circle"
                    image={Snippet}
                    data={{type: TYPE_SNIPPET}}
                  />

                  <StepSectionItem
                    title="Tooltip"
                    icon="isax isax-message"
                    image={Tooltip}
                    data={{type: TYPE_TOOLTIP}}
                  />
                  <StepSectionItem
                    title="Hotspot"
                    icon="isax isax-notification-1"
                    image={Hotspot}
                    data={{type: TYPE_HOTSPOT}}
                  />
                </div>
              </div>
            </div>
            <div className="group">
              <div className="content-manager-header">
                <div className="content-manager-title">Start from a preset</div>
              </div>
              <Tabs key={tab} defaultTab={tab} onTabChange={setTab}>
                <div label={TAB_ALL} />
                <div label={TAB_TOOLTIP} />
                <div label={TAB_MODAL} />
                <div label={TAB_SNIPPET} />
                <div label={TAB_HOTSPOT} />
              </Tabs>

              <div className="sections">
                <div className="section-list">
                  {styledExperiences?.map((experience) => {
                    return experience.steps.map((preset, index) => {
                      const experienceWithFilteredSteps = {
                        ...experience,
                        steps: experience.steps.filter(
                          (s) => s.uid === preset.uid
                        ),
                      };

                      return (
                        <StepSectionItem
                          title={preset.name}
                          description={preset.description}
                          image={Tooltip}
                          data={{preset: experienceWithFilteredSteps}}
                        />
                      );
                    });
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
        {isSurvey && (
          <div className="content-wrapper">
            <div className="sections">
              <div className="section">
                <div className="section-title subtitle-3 n-700">Ask</div>
                <div className="section-list">
                  {SurveyBlocks.filter((block) => block.category === 'Ask').map(
                    (block) => (
                      <StepSectionItem
                        key={block.title}
                        title={block.title}
                        image={block.image}
                        data={{type: block.type}}
                        isSurvey
                      />
                    )
                  )}
                </div>
              </div>
              <Divider />
              <div className="section">
                <div className="section-title subtitle-3 n-700">Test</div>
                <div className="section-list">
                  {SurveyBlocks.filter(
                    (block) => block.category === 'Test'
                  ).map((block) => (
                    <StepSectionItem
                      key={block.title}
                      title={block.title}
                      image={block.image}
                      data={{type: block.type}}
                      isSurvey
                    />
                  ))}
                </div>
              </div>
              <Divider />
              <div className="section">
                <div className="section-title subtitle-3 n-700">Say</div>
                <div className="section-list">
                  {SurveyBlocks.filter((block) => block.category === 'Say').map(
                    (block) => (
                      <StepSectionItem
                        key={block.title}
                        title={block.title}
                        image={block.image}
                        data={{type: block.type}}
                        isSurvey
                      />
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

ContentManager.propTypes = propTypes;

export default ContentManager;
