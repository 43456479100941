import classNames from 'classnames';
import Divider from 'components/Divider';
import RadioGroup from 'components/RadioGroup';
import {addFlag, hasFlag, removeFlag} from 'helpers/bitwise';
import {func, object} from 'prop-types';
import {positionsArr} from 'scenes/PokeBuilder/components/BlockEditor/blocks/PinnedElement';
import ClickableInput from 'scenes/PokeBuilder/components/BlockEditor/components/items/ClickableInput';
import ColorPickerInput from 'scenes/PokeBuilder/components/BlockEditor/components/items/ColorPickerInput';
import PixelPicker from 'scenes/PokeBuilder/components/BlockEditor/components/items/PixelPicker';
import {
  pointerOptions,
  positionOptions,
} from 'scenes/PokeBuilder/components/BlockEditor/components/modals/Position';
import ZIndex from 'scenes/PokeBuilder/components/BlockEditor/components/modals/ZIndex';
import PopupSetting from 'scenes/PokeBuilder/components/BlockEditor/components/PopupSetting';
import {ElementSelector} from 'scenes/SuccessTracker/components/Event/components/EventConditions/components/EditDropdown/components/ElementSelector';
import {
  F_BOOST_SLOT_DOT,
  F_BOOST_SLOT_TOOLTIP,
  F_OPTION_POKE_CARD_WITH_POINTER,
} from 'services/evolution';
import './_Styles.scss';

const propTypes = {
  evolution: object,
  setEvolution: func,
  messenger: object,
};

const PinnedElementEditor = ({evolution, setEvolution, messenger, image}) => {
  const isAuto = evolution?.boostedPositionFlags === 0;
  const isCustom = evolution?.boostedPositionFlags === 256;
  const isFixed = isAuto !== true && isCustom !== true;

  const isHotspot = hasFlag(F_BOOST_SLOT_DOT, evolution.boostFlags);
  const isTooltip = hasFlag(F_BOOST_SLOT_TOOLTIP, evolution.boostFlags);

  const [offsetX, offsetY, offsetCardX = 0, offsetCardY = 0] = (
    evolution?.boostedPositionOffsets || '0;0;0;0'
  ).split(';');

  const selectedAlignment = positionsArr.find(
    (o) => o.value === evolution?.boostedPositionFlags
  );

  const hasPointer = hasFlag(
    F_OPTION_POKE_CARD_WITH_POINTER,
    evolution.optionsFlags
  );

  const style = evolution?.style ?? {};

  const handleStyleChange = (changes) => {
    setEvolution({
      ...evolution,
      style: {...style, ...changes},
    });
  };

  return (
    <div className="pinned-element-editor">
      <div className="section-item target-picker">
        <ElementSelector
          data={{
            querySelector: evolution?.boostedQueryselector,
            file: evolution?.file,
            querySelectorManual: evolution?.querySelectorManual,
          }}
          onChange={(data) => {
            setEvolution({
              ...evolution,
              boostedQueryselector: data.querySelector,
              file: data.file,
              querySelectorManual: data.querySelectorManual,
            });
          }}
          withElementsCount
          onSelectElement={messenger.sendSelectTargetElement}
          image={image}
        />
      </div>
      <Divider />
      <div className="section-item">
        <RadioGroup
          value={isAuto ? 'auto' : isFixed ? 'fixed' : 'custom'}
          options={positionOptions.filter((o) => {
            if (isHotspot === true) {
              return o.value !== 'auto';
            }
            return true;
          })}
          onSelect={(v) => {
            if (v === 'auto') {
              setEvolution({...evolution, boostedPositionFlags: 0});
            } else if (v === 'fixed') {
              setEvolution({...evolution, boostedPositionFlags: 1});
            } else {
              setEvolution({
                ...evolution,
                boostedPositionFlags: 256,
                ...(evolution.boostedPositionFlags !== 256
                  ? {boostedPositionOffsets: '0;0;0;110'}
                  : {}),
              });
            }
          }}
        />
      </div>
      {isCustom && (
        <>
          {isHotspot ? (
            <>
              <div className="section-item">
                <div className="section-item-title">Hotspot offset X</div>
                <div className="section-item-content">
                  <PixelPicker
                    value={offsetX}
                    min={-400}
                    max={400}
                    onChange={(value) => {
                      messenger.sendForceHotspotView(evolution.uid, true);

                      setEvolution({
                        ...evolution,
                        boostedPositionOffsets: `${value};${offsetY};${offsetCardX};${offsetCardY}`,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="section-item">
                <div className="section-item-title">Hotspot offset Y</div>
                <div className="section-item-content">
                  <PixelPicker
                    value={offsetY}
                    min={-400}
                    max={400}
                    onChange={(value) => {
                      messenger.sendForceHotspotView(evolution.uid, true);

                      setEvolution({
                        ...evolution,
                        boostedPositionOffsets: `${offsetX};${value};${offsetCardX};${offsetCardY}`,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="section-item">
                <div className="section-item-title">Card offset X</div>
                <div className="section-item-content">
                  <PixelPicker
                    value={offsetCardX}
                    min={-400}
                    max={400}
                    onChange={(value) => {
                      messenger.sendForceHotspotView(evolution.uid, false);

                      setEvolution({
                        ...evolution,
                        boostedPositionOffsets: `${offsetX};${offsetY};${value};${offsetCardY}`,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="section-item">
                <div className="section-item-title">Card offset Y</div>
                <div className="section-item-content">
                  <PixelPicker
                    value={offsetCardY}
                    min={-400}
                    max={400}
                    onChange={(value) => {
                      messenger.sendForceHotspotView(evolution.uid, false);

                      setEvolution({
                        ...evolution,
                        boostedPositionOffsets: `${offsetX};${offsetY};${offsetCardX};${value}`,
                      });
                    }}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="section-item">
                <div className="section-item-title">Offset X</div>
                <div className="section-item-content">
                  <PixelPicker
                    value={offsetX}
                    min={-400}
                    max={400}
                    onChange={(value) =>
                      setEvolution({
                        ...evolution,
                        boostedPositionOffsets: `${value};${offsetY}`,
                      })
                    }
                  />
                </div>
              </div>
              <div className="section-item">
                <div className="section-item-title">Offset Y</div>
                <div className="section-item-content">
                  <PixelPicker
                    value={offsetY}
                    min={-400}
                    max={400}
                    onChange={(value) =>
                      setEvolution({
                        ...evolution,
                        boostedPositionOffsets: `${offsetX};${value}`,
                      })
                    }
                  />
                </div>
              </div>
            </>
          )}
        </>
      )}
      {isFixed && (
        <div className="alignment-figure-wrapper">
          <div className="alignment-figure">
            <div className="alignment-label">
              {selectedAlignment != null ? (
                <>Always show from {selectedAlignment.position}</>
              ) : (
                <>Select a position</>
              )}
            </div>
            {positionsArr.map((o) => {
              if (isHotspot && o.value > 128) {
                return <></>;
              }
              return (
                <div
                  className={classNames('position-dot', o.position, {
                    selected: evolution?.boostedPositionFlags === o.value,
                  })}
                  onClick={() =>
                    setEvolution({...evolution, boostedPositionFlags: o.value})
                  }
                />
              );
            })}
          </div>
        </div>
      )}
      {isTooltip && (
        <>
          <div className="section-item">
            <div className="section-item-title">Target border</div>
            <div className="section-item-content">
              <ColorPickerInput
                title="Target border color"
                value={
                  style.targetBorderColor || style.background?.primaryColor
                }
                onChange={(value) =>
                  handleStyleChange({targetBorderColor: value})
                }
                erasable={style.targetBorderColor != null}
              />
            </div>
          </div>
          <div className="section-item">
            <div className="section-item-title">Pointer</div>
            <div className="section-item-content">
              <RadioGroup
                value={hasPointer}
                options={pointerOptions}
                onSelect={(v) => {
                  if (v === true) {
                    setEvolution({
                      ...evolution,
                      optionsFlags: addFlag(
                        F_OPTION_POKE_CARD_WITH_POINTER,
                        evolution.optionsFlags
                      ),
                    });
                  } else {
                    setEvolution({
                      ...evolution,
                      optionsFlags: removeFlag(
                        F_OPTION_POKE_CARD_WITH_POINTER,
                        evolution.optionsFlags
                      ),
                    });
                  }
                }}
              />
            </div>
          </div>
          {hasPointer === true && (
            <div className="section-item">
              <div className="section-item-title">Pointer color</div>
              <div className="section-item-content">
                <ColorPickerInput
                  title="Pointer color"
                  value={style.pointerColor || style.background?.primaryColor}
                  onChange={(value) =>
                    handleStyleChange({
                      pointerColor: value,
                    })
                  }
                  erasable={style.pointerColor != null}
                />
              </div>
            </div>
          )}
        </>
      )}
      <div className="section-item z-index">
        <div className="section-item-title">Z-index</div>
        <div className="section-item-content">
          <PopupSetting
            trigger={
              <ClickableInput
                className="position-z-index"
                value={
                  evolution.boostedZIndex != null
                    ? `${evolution.boostedZIndex}`
                    : 'Auto'
                }
                leftLabel={
                  <div className="icon-wrapper">
                    <i className="icon-duplicate" />
                  </div>
                }
              />
            }
            title="Z-index"
            content={
              <ZIndex
                value={evolution.boostedZIndex}
                onChange={(value) =>
                  setEvolution({
                    ...evolution,
                    boostedZIndex: value,
                  })
                }
              />
            }
          />
        </div>
      </div>
    </div>
  );
};

PinnedElementEditor.propTypes = propTypes;

export default PinnedElementEditor;
