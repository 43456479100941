import classNames from 'classnames';
import Divider from 'components/Divider';
import {func, object} from 'prop-types';
import React from 'react';
import './_Styles.scss';

const propTypes = {
  evolution: object,
  onStartPreview: func,
  onStartFromOrigin: func,
  onNavigateToPage: func,
};

const PreviewSelector = ({
  evolution,
  onStartPreview = () => {},
  onStartFromOrigin = () => {},
  onNavigateToPage = () => {},
}) => {
  return (
    <div className="preview-selector">
      <div className="preview-selector-header subtitle-4 n-800">
        Choose preview starting point
      </div>
      <Divider />
      <div className="preview-selector-center">
        <div className={classNames('preview-option')} onClick={onStartPreview}>
          <i className="isax isax-play-circle n-700" />
          <div className="preview-option-content">
            <div className="preview-option-title body-3 n-800">
              Start preview here
            </div>
            <div className="preview-option-description body-4 n-600">
              Begin the preview from this current page
            </div>
          </div>
        </div>
        {evolution?.boostedActiveUrl && (
          <div
            className={classNames('preview-option')}
            onClick={onStartFromOrigin}>
            <i className="isax isax-repeat-circle n-700" />
            <div className="preview-option-content">
              <div className="preview-option-title body-3 n-800">
                Begin preview at origin URL
              </div>
              <div className="preview-option-description body-4 n-600">
                {evolution?.boostedActiveUrl}
              </div>
            </div>
          </div>
        )}
        <div
          className={classNames('preview-option')}
          onClick={onNavigateToPage}>
          <i className="isax isax-mouse-square n-700" />
          <div className="preview-option-content">
            <div className="preview-option-title body-3 n-800">
              Go to specific page
            </div>
            <div className="preview-option-description body-4 n-600">
              Navigate to a page of your choice
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

PreviewSelector.propTypes = propTypes;

export default PreviewSelector;
