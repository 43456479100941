import classNames from 'classnames';
import Divider from 'components/Divider';
import InputGroup from 'components/Input';
import RadioGroup from 'components/RadioGroup';
import TextArea from 'components/TextArea';
import Tooltip from 'components/Tooltip';
import {useLayoutEffect, useRef} from 'react';

const DefaultElementEditor = ({count, state, setState}) => {
  const textAreaRef = useRef(null);

  const {querySelector, querySelectorManual, image} = state;

  const withText = true;
  const withMultiple = true;

  const {elementText, cssSelector, matchElement} = querySelectorManual || {};

  function adjustHeight() {
    if (textAreaRef.current == null) {
      return;
    }

    textAreaRef.current.style.height = 'inherit';
    textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
  }

  useLayoutEffect(adjustHeight, []);

  return (
    <div className="content">
      {querySelectorManual != null ? (
        <div className="manual-wrapper">
          <div
            className={classNames('element-count', {
              multiple: count > 1,
              empty: count === 0,
              perfect: count === 1,
            })}>
            <i className="icon-layers" />
            <span className="body-3">
              {count} element{count > 1 ? 's' : ''} found
            </span>
          </div>
          <Divider />
          {withText && (
            <div className="element">
              <div className="label body-3">
                Element text
                <Tooltip
                  className="tooltip-manual-selector"
                  dark
                  offsetY={8}
                  trigger={<i className="icon-question-circle n-700" />}
                  contentStyle={{zIndex: 10000}}>
                  Elements that match with the text you write here will be
                  selected.
                </Tooltip>
              </div>
              <div className="content">
                <InputGroup
                  type="text"
                  placeholder="Enter element text"
                  value={elementText}
                  onChange={(e) =>
                    setState({
                      ...state,
                      querySelectorManual: {
                        ...querySelectorManual,
                        elementText: e.target.value,
                      },
                    })
                  }
                />
              </div>
            </div>
          )}
          <div className="element">
            <div className="label body-3">
              CSS selector
              <Tooltip
                className="tooltip-manual-selector"
                dark
                offsetY={8}
                trigger={<i className="icon-question-circle n-700" />}
                contentStyle={{zIndex: 10000}}>
                If text is generic or absent, use this CSS selector. If both are
                filled, Jimo will match elements using both criteria.
              </Tooltip>
            </div>
            <div className="content">
              <TextArea
                innerRef={textAreaRef}
                placeholder="Enter element css selector"
                value={cssSelector}
                onChange={(e) => {
                  adjustHeight();
                  setState({
                    ...state,
                    querySelectorManual: {
                      ...querySelectorManual,
                      cssSelector: e.target.value,
                    },
                  });
                }}
              />
            </div>
          </div>
          {withMultiple && (
            <div className="element">
              <div className="label body-3">If multiple matches, select</div>
              <div className="content">
                <RadioGroup
                  options={[
                    {label: 'First element', value: 'first'},
                    {label: 'All elements', value: 'all'},
                  ]}
                  value={matchElement}
                  onSelect={(value) => {
                    setState({
                      ...state,
                      querySelectorManual: {
                        ...querySelectorManual,
                        matchElement: value,
                      },
                    });
                  }}
                />
              </div>
            </div>
          )}
          <Divider />
          <div
            className="switch-to-auto b-400"
            onClick={() => {
              setState({
                ...state,
                querySelectorManual: null,
              });
            }}>
            <i className="icon-target" />
            <span>Switch to auto selection</span>
          </div>
        </div>
      ) : (
        <div className="auto-wrapper">
          {image != null && (
            <div className="element-image-wrapper">
              <img src={image} alt="element" />
            </div>
          )}
          <div className="preview-content">
            <div className="preview-query-selector body-3 n-700">
              {querySelector}
            </div>
            <div
              className="edit-btn subtitle-4 b-400"
              onClick={() =>
                setState({
                  ...state,
                  querySelectorManual: {
                    elementText: '',
                    cssSelector: querySelector,
                    matchElement: 'first',
                  },
                })
              }>
              edit
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DefaultElementEditor;
