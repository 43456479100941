import {buildLogic} from 'scenes/SuccessTracker/components/Event/components/EventConditions/utils';
import {
  BLOCK_TYPE_ANIMATION,
  BLOCK_TYPE_BODY,
  BLOCK_TYPE_CHECKLIST,
  BLOCK_TYPE_CHECKLIST_DISMISS,
  BLOCK_TYPE_CHECKLIST_HEADER,
  BLOCK_TYPE_CHECKLIST_HEADER_DESCRIPTION,
  BLOCK_TYPE_CHECKLIST_HEADER_PROGRESS,
  BLOCK_TYPE_CHECKLIST_HEADER_TITLE,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_CHECKBOX,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_DESCRIPTION,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_MEDIA,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_PRIMARY_CTA,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_SECONDARY_CTA,
  BLOCK_TYPE_CHECKLIST_TASK_ITEM_TITLE,
  BLOCK_TYPE_CHECKLIST_TASK_LIST,
  BLOCK_TYPE_CHECKLIST_TRIGGER,
  BLOCK_TYPE_CHOICE,
  BLOCK_TYPE_CONCEPT,
  BLOCK_TYPE_DISMISS_CROSS,
  BLOCK_TYPE_HINT,
  BLOCK_TYPE_INTERVIEW,
  BLOCK_TYPE_LABEL,
  BLOCK_TYPE_MEDIA,
  BLOCK_TYPE_NPS,
  BLOCK_TYPE_OPEN_QUESTION,
  BLOCK_TYPE_OPINION,
  BLOCK_TYPE_PRIMARY_CTA,
  BLOCK_TYPE_SECONDARY_CTA,
  BLOCK_TYPE_SLIDER,
  BLOCK_TYPE_STEPPER,
  BLOCK_TYPE_TITLE,
  BLOCK_TYPE_USER,
  createStepOptionObj,
  STEP_CONDITION_ACTION_TYPE_DISMISS,
  STEP_CONDITION_ACTION_TYPE_GO_TO_STEP,
  STEP_CONDITION_ACTION_TYPE_MARK_CHECKLIST_TASK_COMPLETED,
  STEP_CONDITION_ACTION_TYPE_SKIP_TASK,
} from 'services/steps';
import {STEPPER_TYPE_DOT_LINE} from 'shared/front/components/Poke/components/BlockStepper';
import {v4 as uuidv4} from 'uuid';
import {CHECKLIST_HEADER_PROGRESS_BAR} from '../BlockEditor/blocks/ChecklistProgress';
import {
  CHECKLIST_TRIGGER_PROGRESS_TYPE_CIRCULAR,
  CHECKLIST_TRIGGER_TYPE_MINIMIZED,
} from '../BlockEditor/blocks/ChecklistTrigger';
import {HINT_TYPE_ICON} from '../BlockEditor/blocks/Hint';
import {HINT_ICON_BUILT_IN} from '../BlockEditor/components/modals/IconPicker';
import ChecklistDismiss from './imgs/checklist_dismiss.svg';
import DismissCross from './imgs/dismiss-cross.svg';
import Label from './imgs/label.svg';
import Media from './imgs/media.svg';
import Paragraph from './imgs/paragraph.svg';
import Primary from './imgs/primary.svg';
import Profile from './imgs/profile.svg';
import Secondary from './imgs/secondary.svg';
import Stepper from './imgs/stepper.svg';
import Title from './imgs/title.svg';

export const BLOCK_CATEGORY_TEXT = 'TEXT';
export const BLOCK_CATEGORY_MEDIA = 'MEDIA';
export const BLOCK_CATEGORY_INTERACTIONS = 'INTERACTIONS';
export const BLOCKS = [
  {
    type: BLOCK_TYPE_TITLE,
    name: 'Heading',
    image: Title,
    category: BLOCK_CATEGORY_TEXT,
  },
  {
    type: BLOCK_TYPE_BODY,
    name: 'Paragraph',
    image: Paragraph,
    category: BLOCK_CATEGORY_TEXT,
  },
  {
    type: BLOCK_TYPE_LABEL,
    name: 'Label',
    image: Label,
    category: BLOCK_CATEGORY_TEXT,
  },
  {
    type: BLOCK_TYPE_MEDIA,
    name: 'Image/Video',
    image: Media,
    category: BLOCK_CATEGORY_MEDIA,
  },
  {
    type: BLOCK_TYPE_USER,
    name: 'Profile',
    image: Profile,
    category: BLOCK_CATEGORY_MEDIA,
  },
  {
    type: BLOCK_TYPE_PRIMARY_CTA,
    name: 'Primary button',
    image: Primary,
    category: BLOCK_CATEGORY_INTERACTIONS,
  },
  {
    type: BLOCK_TYPE_SECONDARY_CTA,
    name: 'Secondary button',
    image: Secondary,
    category: BLOCK_CATEGORY_INTERACTIONS,
  },
  {
    type: BLOCK_TYPE_STEPPER,
    name: 'Stepper',
    image: Stepper,
    category: BLOCK_CATEGORY_INTERACTIONS,
  },
  {
    type: BLOCK_TYPE_CHECKLIST_HEADER_TITLE,
    name: 'Heading',
    image: Title,
    category: BLOCK_CATEGORY_TEXT,
  },
  {
    type: BLOCK_TYPE_CHECKLIST_HEADER_DESCRIPTION,
    name: 'Paragraph',
    image: Paragraph,
    category: BLOCK_CATEGORY_TEXT,
  },
  {
    type: BLOCK_TYPE_CHECKLIST_HEADER_PROGRESS,
    name: 'Progress',
    image: Stepper,
    category: BLOCK_CATEGORY_INTERACTIONS,
  },
  {
    type: BLOCK_TYPE_CHECKLIST_TASK_ITEM_TITLE,
    name: 'Heading',
    image: Title,
    category: BLOCK_CATEGORY_TEXT,
  },
  {
    type: BLOCK_TYPE_CHECKLIST_TASK_ITEM_DESCRIPTION,
    name: 'Paragraph',
    image: Paragraph,
    category: BLOCK_CATEGORY_TEXT,
  },
  {
    type: BLOCK_TYPE_CHECKLIST_TASK_ITEM_MEDIA,
    name: 'Image/Video',
    image: Media,
    category: BLOCK_CATEGORY_MEDIA,
  },
  {
    type: BLOCK_TYPE_CHECKLIST_TASK_ITEM_PRIMARY_CTA,
    name: 'Primary button',
    image: Primary,
    category: BLOCK_CATEGORY_INTERACTIONS,
  },
  {
    type: BLOCK_TYPE_CHECKLIST_TASK_ITEM_SECONDARY_CTA,
    name: 'Secondary button',
    image: Secondary,
    category: BLOCK_CATEGORY_INTERACTIONS,
  },
  {
    type: BLOCK_TYPE_CHECKLIST_HEADER,
    name: 'Header',
    image: Title,
  },
  {
    type: BLOCK_TYPE_CHECKLIST_TASK_LIST,
    name: 'Task List',
    image: Paragraph,
  },
  {
    type: BLOCK_TYPE_CHECKLIST_DISMISS,
    name: 'Dismiss',
    image: ChecklistDismiss,
  },
  {
    type: BLOCK_TYPE_DISMISS_CROSS,
    name: 'Cross',
    image: DismissCross,
    category: BLOCK_CATEGORY_INTERACTIONS,
  },
];

export const CTA_IN_NEW_TAB = 1;
export const CHOICE_CAN_MULTIPLE = 2;
export const NPS_INTERACTIVE_BACKGROUND = 4;
export const SLIDER_ABOVE_EMOJI_ANIMATION = 8;
export const F_BLOCK_ZOOM_ON_MEDIA_CLICK = 16;

/*
{
  fontColor?: string; // title, body, label, primary & secondary cta
  fontSize?: string; // title, body, label, primary & secondary cta, choice
  fontFamily?: string; // title, body, label, primary & secondary cta, choice
  align?: string; // title, body, label, primary & secondary cta, stepper
  position?: string; // media, stepper
  padding?: string; // media, label, primary & secondary cta, choice
  borderRadius?: string; // media, user, choice
  borderColor?: string; // label
  primaryColor?: string; // label, primary & secondary cta, stepper, choice, slider
  secondaryColor?: string; // stepper, slider
  height?: string; // slider
}
*/
export const getDefaultBlockFromType = (type, theme) => {
  const style = theme?.style?.blocksStyle?.[type]?.style;

  if (type === BLOCK_TYPE_TITLE) {
    return {
      uid: uuidv4(),
      type: BLOCK_TYPE_TITLE,
      value: 'Title|-|none|-|',
      rawValue: [
        {
          object: 'block',
          type: 'paragraph',
          children: [
            {
              object: 'text',
              text: 'Title',
            },
          ],
          data: {},
        },
      ],
      style: style || {
        fontColor: '#000000',
        fontSize: 16,
        fontFamily: 'Montserrat',
        align: 'left', // left|center|right
        fontWeight: '600',
      },
    };
  } else if (type === BLOCK_TYPE_BODY) {
    return {
      uid: uuidv4(),
      type: BLOCK_TYPE_BODY,
      value: 'Body',
      rawValue: [
        {
          object: 'block',
          type: 'paragraph',
          children: [
            {
              object: 'text',
              text: 'Body',
            },
          ],
          data: {},
        },
      ],
      style: style || {
        fontColor: '#000000',
        fontSize: 14,
        fontFamily: 'Inter',
        align: 'left', // left|center|right
        fontWeight: '400',
      },
    };
  } else if (type === BLOCK_TYPE_LABEL) {
    return {
      uid: uuidv4(),
      type: BLOCK_TYPE_LABEL,
      value: 'New', // name
      style: style || {
        fontColor: '#000000',
        fontFamily: 'Inter',
        fontSize: 12,
        primaryColor: '#FFFFFF',
        borderColor: '#FFFFFF',
        padding: 8,
        borderRadius: 8,
        align: 'left', // left|center|right
      },
    };
  } else if (type === BLOCK_TYPE_MEDIA) {
    return {
      uid: uuidv4(),
      type: BLOCK_TYPE_MEDIA,
      value: 'image;;logo;fill', // (image|video);url;alt-text;(fill|fit)
      file: null, // File entity of the media
      style: style || {
        position: 'top', // top|content-in
        padding: 8,
        borderRadius: 8,
      },
    };
  } else if (type === BLOCK_TYPE_USER) {
    return {
      uid: uuidv4(),
      type: BLOCK_TYPE_USER,
      value: 'John Doe;CPO @Lance;', // name;role;avatarUrl
      file: null,
      style: style || {
        borderRadius: 6,
      },
    };
  } else if (type === BLOCK_TYPE_STEPPER) {
    return {
      uid: uuidv4(),
      type: BLOCK_TYPE_STEPPER,
      style: style || {
        type: STEPPER_TYPE_DOT_LINE, // text|dot|dot-line|line
        position: 'top', // top|bottom
        align: 'left', // left|center|right
        primaryColor: '#3d69ff', // active color
        secondaryColor: '#c3c3c3', // default color
      },
    };
  } else if (type === BLOCK_TYPE_PRIMARY_CTA) {
    return {
      uid: uuidv4(),
      type: BLOCK_TYPE_PRIMARY_CTA,
      value: 'continue;step-next;none;', // text;(none|step-next|step-prev|close);(none|url-open|post-open);(url|postUid)
      flags: 0,
      style: style || {
        fontFamily: 'Inter',
        fontSize: 16,
        fontColor: '#000000',
        primaryColor: '#FFFFFF', // default color (hover and active are based on default but play with transparency
        borderColor: '#00000052',
        borderRadius: 10,
        align: 'left', // left|center|right
        padding: 8,
      },
      actions: [
        {
          uid: uuidv4(),
          type: STEP_CONDITION_ACTION_TYPE_GO_TO_STEP,
          value: 'next-step',
        },
      ],
    };
  } else if (type === BLOCK_TYPE_SECONDARY_CTA) {
    return {
      uid: uuidv4(),
      type: BLOCK_TYPE_SECONDARY_CTA,
      value: 'text;step-prev;none;', // text;(step-next|step-prev|url-open|close|post-open);(url|postUid)
      flags: 0,
      style: style || {
        fontFamily: 'Inter',
        fontSize: 16,
        fontColor: '#000000',
        primaryColor: '#FFFFFF', // default color (hover and active are based on default but play with transparency
        borderColor: '#00000052',
        borderRadius: 10,
        align: 'left', // left|center|right
        padding: 8,
      },
    };
  } else if (type === BLOCK_TYPE_DISMISS_CROSS) {
    return {
      uid: uuidv4(),
      type: BLOCK_TYPE_DISMISS_CROSS,
      style: style || {
        fontSize: 16,
        fontColor: '#000000',
        borderRadius: 10,
        primaryColor: null,
      },
      actions: [
        {
          uid: uuidv4(),
          type: STEP_CONDITION_ACTION_TYPE_DISMISS,
        },
      ],
    };
  } else if (type === BLOCK_TYPE_ANIMATION) {
    return {
      uid: uuidv4(),
      type: BLOCK_TYPE_ANIMATION,
      value: 'confetti',
    };
  } else if (type === BLOCK_TYPE_CHOICE) {
    return {
      uid: uuidv4(),
      type: BLOCK_TYPE_CHOICE,
      flags: CHOICE_CAN_MULTIPLE,
      options: [
        createStepOptionObj({indexOrder: 0, content: 'Option 1'}),
        createStepOptionObj({indexOrder: 1, content: 'Option 2'}),
      ],
      style: style || {
        fontSize: 14,
        fontFamily: 'Inter',
        padding: 8,
        borderRadius: 8,
        primaryColor: '#000000', // default color also used for the font (hover and active are based on default but play with transparency)
        disposition: 'inline', // inline|block
      },
    };
  } else if (type === BLOCK_TYPE_SLIDER) {
    return {
      uid: uuidv4(),
      type: BLOCK_TYPE_SLIDER,
      value: 'emoji;🔥;really bad;really good', // (smiley|emoji);customEmoji;labelLeft;labelRight
      flags: SLIDER_ABOVE_EMOJI_ANIMATION,
      style: style || {
        primaryColor: '#FFFFFF', // Slider tracker color
        secondaryColor: '#000000', // Slider background color
        height: 8,
      },
    };
  } else if (type === BLOCK_TYPE_NPS) {
    return {
      uid: uuidv4(),
      type: BLOCK_TYPE_NPS,
      value: 'feel very bad;feel very good', // labelLeft;labelRight
      flags: NPS_INTERACTIVE_BACKGROUND,
      style: style || {
        padding: 8,
        fontSize: 14,
      },
    };
  } else if (type === BLOCK_TYPE_OPINION) {
    return {
      uid: uuidv4(),
      type: BLOCK_TYPE_OPINION,
      value: 'smiley;5;very bad;very good', // (smiley|emoji|custom);numberOfFeelings;labelLeft;labelRight
      style: style || {
        padding: 8,
        fontSize: 20,
      },
    };
  } else if (type === BLOCK_TYPE_OPEN_QUESTION) {
    return {
      uid: uuidv4(),
      type: BLOCK_TYPE_OPEN_QUESTION,
      value: 'Enter your answer', // placeholder
      style: style || {
        primaryColor: '#FFFFFF',
        borderRadius: 6,
      },
    };
  } else if (type === BLOCK_TYPE_CONCEPT) {
    return {
      uid: uuidv4(),
      type: BLOCK_TYPE_CONCEPT,
      value: 'image;;;', // type;conceptTestUrl;thumbnailUrl;alt-text
      file: null, // File entity of the thumbnail
      style: style || {
        borderRadius: 8,
      },
    };
  } else if (type === BLOCK_TYPE_INTERVIEW) {
    return {
      uid: uuidv4(),
      type: BLOCK_TYPE_INTERVIEW,
      value: 'Book a call;;Not now', // buttonBookText;calendarUrl;buttonCancelText
      style: style || {
        primaryColor: '#FFFFFF', // Background color of the book button
        secondaryColor: '#FFFFFF', // Background color of the cancel button
        fontPrimaryColor: '#000000', // text color of the book button
        fontSecondaryColor: '#000000', // text color of the cancel button
        borderPrimaryColor: '#000000', // border color of the book button
        borderSecondaryColor: '#000000', // border color of the cancel button
        borderRadius: 6,
      },
    };
  } else if (type === BLOCK_TYPE_HINT) {
    return {
      uid: uuidv4(),
      type: BLOCK_TYPE_HINT,
      value: 'New',
      style: style || {
        type: HINT_TYPE_ICON,
        iconSource: HINT_ICON_BUILT_IN,
        iconName: 'help',
        iconUrl: null,
        iconColor: '#000000',
        pulsating: true,
        fontWeight: '500',
        fontFamily: 'Inter',
        backgroundColor: '#FF4646',
        color: '#FFFFFF',
        borderColor: null,
        fontSize: 20,
        padding: 8,
        borderRadius: 16,
        offsetX: 0,
        offsetY: 0,
        horizontalAlign: 'left',
        verticalAlign: 'top',
      },
    };
  } else if (type === BLOCK_TYPE_CHECKLIST_HEADER) {
    return {
      uid: uuidv4(),
      type: BLOCK_TYPE_CHECKLIST_HEADER,
      style: style || {
        backgroundColor: '#0063ffff',
        borderColor: null,
      },
    };
  } else if (type === BLOCK_TYPE_CHECKLIST_TASK_LIST) {
    return {
      uid: uuidv4(),
      type: BLOCK_TYPE_CHECKLIST_TASK_LIST,
      style: style || {},
    };
  } else if (type === BLOCK_TYPE_CHECKLIST_TASK_ITEM) {
    return {
      uid: uuidv4(),
      type: BLOCK_TYPE_CHECKLIST_TASK_ITEM,
      value: 'New task',
      style: style || {
        borderColor: '#0000001a',
      },
      triggers: [
        {
          uid: uuidv4(),
          conditions: [],
          logic: buildLogic(),
          actions: [
            {
              uid: uuidv4(),
              type: STEP_CONDITION_ACTION_TYPE_MARK_CHECKLIST_TASK_COMPLETED,
            },
          ],
        },
      ],
    };
  } else if (type === BLOCK_TYPE_CHECKLIST_TASK_ITEM_CHECKBOX) {
    return {
      uid: uuidv4(),
      type: BLOCK_TYPE_CHECKLIST_TASK_ITEM_CHECKBOX,
      style: style || {
        incompleteBackgroundColor: '#ecececff',
        incompleteBorderColor: null,
        completedBackgroundColor: '#0063ffff',
        completedBorderColor: null,
        iconSource: HINT_ICON_BUILT_IN,
        iconName: 'simple-check',
        iconUrl: null,
        iconColor: '#ffffffff',
        borderStyle: 'solid',
        borderRadius: 16,
      },
    };
  } else if (type === BLOCK_TYPE_CHECKLIST_TASK_ITEM_TITLE) {
    return {
      uid: uuidv4(),
      type: BLOCK_TYPE_CHECKLIST_TASK_ITEM_TITLE,
      value: 'Title|-|none|-|',
      rawValue: [
        {
          object: 'block',
          type: 'paragraph',
          children: [
            {
              object: 'text',
              text: 'Title',
            },
          ],
          data: {},
        },
      ],
      style: style || {
        fontColor: '#000000',
        fontSize: 14,
        fontFamily: 'Inter',
        fontWeight: '400',
      },
    };
  } else if (type === BLOCK_TYPE_CHECKLIST_TASK_ITEM_DESCRIPTION) {
    return {
      uid: uuidv4(),
      type: BLOCK_TYPE_CHECKLIST_TASK_ITEM_DESCRIPTION,
      value: 'Body',
      rawValue: [
        {
          object: 'block',
          type: 'paragraph',
          children: [
            {
              object: 'text',
              text: 'Body',
            },
          ],
          data: {},
        },
      ],
      style: style || {
        fontColor: '#000000',
        fontSize: 12,
        fontFamily: 'Inter',
      },
    };
  } else if (type === BLOCK_TYPE_CHECKLIST_TASK_ITEM_MEDIA) {
    return {
      uid: uuidv4(),
      type: BLOCK_TYPE_CHECKLIST_TASK_ITEM_MEDIA,
      value: 'image;;logo;fill', // (image|video);url;alt-text;(fill|fit)
      file: null, // File entity of the media
      style: style || {
        padding: 8,
        borderRadius: 8,
      },
    };
  } else if (type === BLOCK_TYPE_CHECKLIST_TASK_ITEM_PRIMARY_CTA) {
    return {
      uid: uuidv4(),
      type: BLOCK_TYPE_CHECKLIST_TASK_ITEM_PRIMARY_CTA,
      value: 'Go',
      flags: 0,
      style: style || {
        fontFamily: 'Inter',
        fontSize: 14,
        fontColor: '#000000',
        fontWeight: '400',
        primaryColor: '#FFFFFF', // default color (hover and active are based on default but play with transparency
        borderColor: '#00000052',
        borderRadius: 8,
        padding: 8,
      },
    };
  } else if (type === BLOCK_TYPE_CHECKLIST_TASK_ITEM_SECONDARY_CTA) {
    return {
      uid: uuidv4(),
      type: BLOCK_TYPE_CHECKLIST_TASK_ITEM_SECONDARY_CTA,
      value: 'Skip',
      flags: 0,
      style: style || {
        fontFamily: 'Inter',
        fontSize: 14,
        fontColor: '#000000',
        primaryColor: '#FFFFFF', // default color (hover and active are based on default but play with transparency
        borderColor: '#00000052',
        borderRadius: 8,
        align: 'left', // left|center|right
        padding: 8,
      },
      actions: [
        {
          uid: uuidv4(),
          type: STEP_CONDITION_ACTION_TYPE_SKIP_TASK,
        },
      ],
    };
  } else if (type === BLOCK_TYPE_CHECKLIST_TRIGGER) {
    return {
      uid: uuidv4(),
      type: BLOCK_TYPE_CHECKLIST_TRIGGER,
      value: 'Get started 🚀',
      style: style || {
        type: CHECKLIST_TRIGGER_TYPE_MINIMIZED,
        offsetX: 0,
        offsetY: 0,
        position: 'bottom',
        width: null,
        progressType: CHECKLIST_TRIGGER_PROGRESS_TYPE_CIRCULAR,
        progressColor: '#FF4646',
        shadow: {color: null, opacity: 0, x: 0, y: 0, blur: 0},
        radius: 0,
        backgroundColor: '#FF4646',
        color: '#FFFFFF',
        fontFamily: 'Inter',
      },
    };
  } else if (type === BLOCK_TYPE_CHECKLIST_HEADER_TITLE) {
    return {
      uid: uuidv4(),
      type: BLOCK_TYPE_CHECKLIST_HEADER_TITLE,
      value: 'Title',
      rawValue: [
        {
          object: 'block',
          type: 'paragraph',
          children: [
            {
              object: 'text',
              text: 'Title',
            },
          ],
          data: {},
        },
      ],
      style: style || {
        fontColor: '#ffffff',
        fontSize: 16,
        fontFamily: 'Montserrat',
        align: 'left',
        fontWeight: '500',
      },
    };
  } else if (type === BLOCK_TYPE_CHECKLIST_HEADER_DESCRIPTION) {
    return {
      uid: uuidv4(),
      type: BLOCK_TYPE_CHECKLIST_HEADER_DESCRIPTION,
      value: 'Body',
      rawValue: [
        {
          object: 'block',
          type: 'paragraph',
          children: [
            {
              object: 'text',
              text: 'Body',
            },
          ],
          data: {},
        },
      ],
      style: style || {
        fontColor: '#ffffff',
        fontSize: 12,
        fontFamily: 'Inter',
        fontWeight: '500',
      },
    };
  } else if (type === BLOCK_TYPE_CHECKLIST_HEADER_PROGRESS) {
    return {
      uid: uuidv4(),
      type: BLOCK_TYPE_CHECKLIST_HEADER_PROGRESS,
      value: CHECKLIST_HEADER_PROGRESS_BAR,
      style: style || {
        fontFamily: 'Inter',
        fontSize: 14,
        primaryColor: '#ffffffff',
        secondaryColor: '#1452a1ff',
      },
    };
  } else if (type === BLOCK_TYPE_CHECKLIST_DISMISS) {
    return {
      uid: uuidv4(),
      type: BLOCK_TYPE_CHECKLIST_DISMISS,
      value: 'Dismiss Checklist',
      style: style || {
        fontFamily: 'Inter',
        fontSize: 14,
        fontColor: '#000000',
        fontWeight: '500',
      },
    };
  }

  // Theme only blocks
  else if (type === BLOCK_TYPE_CHECKLIST) {
    return {
      style: {
        backgroundColor: '#FFFFFF',
        borderRadius: 12,
        shadow: {color: '#0000000f', x: 0, y: 8, blur: 12},
        borderColor: '#0000000f',
      },
    };
  }

  return;
};
