import classNames from 'classnames';
import Divider from 'components/Divider';
import {toastDanger} from 'components/Toaster';
import {BuilderContext} from 'contextes/builder';
import {errorHelpers} from 'helpers';
import {addFlag, hasFlag, removeFlag} from 'helpers/bitwise';
import {func, string} from 'prop-types';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {InAppBuilderContext} from 'scenes/Pushes/context';
import {ElementSelector} from 'scenes/SuccessTracker/components/Event/components/EventConditions/components/EditDropdown/components/ElementSelector';
import {fileService} from 'services';
import {
  F_BOOST_SLOT_DOT,
  F_BOOST_SLOT_TOOLTIP,
  F_OPTION_POKE_CARD_WITH_POINTER,
} from 'services/evolution';
import {Swaler} from 'swaler';
import RadioGroup from '../../../../../../../components/RadioGroup';
import PopupSetting from '../../PopupSetting';
import ClickableInput from '../../items/ClickableInput';
import ColorPickerInput from '../../items/ColorPickerInput';
import PixelPicker from '../../items/PixelPicker';
import ZIndex from '../ZIndex';
import './_Styles.scss';

const propTypes = {
  value: string,
  onChange: func,
  setCloseOnDocumentClick: func,
};

const defaultProps = {
  value: {},
  onChange: () => {},
  setCloseOnDocumentClick: () => {},
};

export const positionOptions = [
  {label: 'Auto', value: 'auto'},
  {label: 'Fixed', value: 'fixed'},
  {label: 'Custom', value: 'custom'},
];

export const positionsArr = [
  {position: 'top-left', value: 1},
  {position: 'top', value: 2},
  {position: 'top-right', value: 4},
  {position: 'right', value: 8},
  {position: 'bottom-right', value: 16},
  {position: 'bottom', value: 32},
  {position: 'bottom-left', value: 64},
  {position: 'left', value: 128},
  {position: 'right-top', value: 512},
  {position: 'right-bottom', value: 1024},
  {position: 'left-bottom', value: 2048},
  {position: 'left-top', value: 4096},
];

export const pointerOptions = [
  {label: 'Yes', value: true},
  {label: 'No', value: false},
];

const logger = new Swaler('Position');

const Position = ({
  evolution,
  setEvolution,
  setCloseOnDocumentClick = () => {},
}) => {
  const {
    evolution: parentEvolution,
    setEvolution: setParentEvolution,
    controlledEvolution,
    isInApp,
    messenger,
    selectedStepId,
  } = useContext(BuilderContext);

  const {goToEditInApp, stopInAppEditing} = useContext(InAppBuilderContext);

  const [showSelectElementModal, setShowSelectElementModal] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const parentEvolutionRef = useRef();
  const evolutionRef = useRef();

  const style = evolution?.style ?? {};

  useEffect(() => {
    setCloseOnDocumentClick(!showSelectElementModal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSelectElementModal]);

  useEffect(() => {
    parentEvolutionRef.current = parentEvolution;
  }, [parentEvolution]);

  useEffect(() => {
    evolutionRef.current = evolution;
  }, [evolution]);

  const isHotspot = hasFlag(F_BOOST_SLOT_DOT, evolution.boostFlags);
  const isTooltip = hasFlag(F_BOOST_SLOT_TOOLTIP, evolution.boostFlags);

  const isAuto = evolution?.boostedPositionFlags === 0;
  const isCustom = evolution?.boostedPositionFlags === 256;
  const isFixed = isAuto !== true && isCustom !== true;

  const [offsetX, offsetY, offsetCardX = 0, offsetCardY = 0] = (
    evolution?.boostedPositionOffsets || '0;0;0;0'
  ).split(';');

  const selectedAlignment = positionsArr.find(
    (o) => o.value === evolution?.boostedPositionFlags
  );

  const hasPointer = hasFlag(
    F_OPTION_POKE_CARD_WITH_POINTER,
    evolution.optionsFlags
  );

  const handleStyleChange = (changes) => {
    if (evolution.uid === parentEvolution.uid) {
      setParentEvolution({
        ...parentEvolution,
        style: {...style, ...changes},
      });
    } else {
      setParentEvolution({
        ...parentEvolution,
        tourSteps: parentEvolution.tourSteps.map((tourStep) => {
          if (tourStep.uid === evolution.uid) {
            return {
              ...tourStep,
              style: {...tourStep.style, ...changes},
            };
          }
          return tourStep;
        }),
      });
    }
  };

  const uploadFile = async (file) => {
    if (file == null) {
      return;
    }
    const response = await fetch(file);
    const blob = await response.blob();
    const uploadableFile = new File([blob], 'upload.png', {type: 'image/png'});

    try {
      const uploadedFile = await fileService.uploadPublicFile({
        file: uploadableFile,
      });
      return uploadedFile;
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Failed to upload file', code);
      toastDanger([title, message], {actions});
      return null;
    }
  };

  const handleElementSelected = async (data) => {
    // set loading state
    setParentEvolution({
      ...parentEvolutionRef.current,
      tourSteps: parentEvolutionRef.current.tourSteps.map((tourStep) => {
        if (tourStep.uid === evolutionRef.current?.uid) {
          return {
            ...tourStep,
            ...data.evolution,
            isUploading: true,
          };
        }
        return tourStep;
      }),
    });
    setIsUploading(true);
    const file = await uploadFile(data.image);
    setIsUploading(false);

    setParentEvolution({
      ...parentEvolutionRef.current,
      tourSteps: parentEvolutionRef.current.tourSteps.map((tourStep) => {
        if (tourStep.uid === evolutionRef.current?.uid) {
          return {
            ...tourStep,
            ...data.evolution,
            isUploading: false,
            ...(file != null
              ? {
                  file,
                }
              : {
                  file: null,
                }),
          };
        }
        return tourStep;
      }),
    });
  };

  return (
    <div className="position-wrapper">
      <div className="section-item target-picker">
        <ElementSelector
          data={{
            querySelector: evolution?.boostedQueryselector,
            file: evolution?.file,
            querySelectorManual: evolution?.querySelectorManual,
          }}
          onChange={(data) => {
            setEvolution({
              ...evolution,
              boostedQueryselector: data.querySelector,
              file: data.file,
              querySelectorManual: data.querySelectorManual,
            });
          }}
          onModalOpen={(isOpen) => setShowSelectElementModal(isOpen)}
          withElementsCount={isInApp}
          onSelectElement={
            isInApp
              ? messenger.sendSelectTargetElement
              : () =>
                  goToEditInApp({
                    data: {
                      title: 'Select target element',
                    },
                    handshakeData: {
                      type: 'SET_MODE',
                      data: {
                        mode: 'ELEMENT_SELECTOR_BUILDER',
                        onlyInput: false,
                        evolution: parentEvolution,
                        stepId: selectedStepId,
                      },
                    },
                    onChildCommunication: (message) => {
                      if (message?.type === 'TARGET_ELEMENT_SELECTED') {
                        handleElementSelected(message.data);
                        stopInAppEditing();
                      }
                      if (message?.type === 'EMBEDDED_BUILDER_CLOSE') {
                        stopInAppEditing();
                      }
                    },
                  })
          }
          isEditingStep
        />
      </div>
      <Divider />
      <div className="section-item">
        <RadioGroup
          value={isAuto ? 'auto' : isFixed ? 'fixed' : 'custom'}
          options={positionOptions.filter((o) => {
            if (isHotspot === true) {
              return o.value !== 'auto';
            }
            return true;
          })}
          onSelect={(v) => {
            if (v === 'auto') {
              setEvolution({...evolution, boostedPositionFlags: 0});
            } else if (v === 'fixed') {
              setEvolution({...evolution, boostedPositionFlags: 1});
            } else {
              setEvolution({
                ...evolution,
                boostedPositionFlags: 256,
                ...(evolution.boostedPositionFlags !== 256
                  ? {boostedPositionOffsets: '0;0;0;110'}
                  : {}),
              });
            }
          }}
        />
      </div>
      {isCustom && (
        <>
          {isHotspot ? (
            <>
              <div className="section-item">
                <div className="section-item-title">Hotspot offset X</div>
                <div className="section-item-content">
                  <PixelPicker
                    value={offsetX}
                    min={-400}
                    max={400}
                    onChange={(value) => {
                      if (isInApp) {
                        messenger.sendForceHotspotView(evolution.uid, true);
                      }
                      setEvolution({
                        ...evolution,
                        boostedPositionOffsets: `${value};${offsetY};${offsetCardX};${offsetCardY}`,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="section-item">
                <div className="section-item-title">Hotspot offset Y</div>
                <div className="section-item-content">
                  <PixelPicker
                    value={offsetY}
                    min={-400}
                    max={400}
                    onChange={(value) => {
                      if (isInApp) {
                        messenger.sendForceHotspotView(evolution.uid, true);
                      }
                      setEvolution({
                        ...evolution,
                        boostedPositionOffsets: `${offsetX};${value};${offsetCardX};${offsetCardY}`,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="section-item">
                <div className="section-item-title">Card offset X</div>
                <div className="section-item-content">
                  <PixelPicker
                    value={offsetCardX}
                    min={-400}
                    max={400}
                    onChange={(value) => {
                      if (isInApp) {
                        messenger.sendForceHotspotView(evolution.uid, false);
                      }
                      setEvolution({
                        ...evolution,
                        boostedPositionOffsets: `${offsetX};${offsetY};${value};${offsetCardY}`,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="section-item">
                <div className="section-item-title">Card offset Y</div>
                <div className="section-item-content">
                  <PixelPicker
                    value={offsetCardY}
                    min={-400}
                    max={400}
                    onChange={(value) => {
                      if (isInApp) {
                        messenger.sendForceHotspotView(evolution.uid, false);
                      }
                      setEvolution({
                        ...evolution,
                        boostedPositionOffsets: `${offsetX};${offsetY};${offsetCardX};${value}`,
                      });
                    }}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="section-item">
                <div className="section-item-title">Offset X</div>
                <div className="section-item-content">
                  <PixelPicker
                    value={offsetX}
                    min={-400}
                    max={400}
                    onChange={(value) =>
                      setEvolution({
                        ...evolution,
                        boostedPositionOffsets: `${value};${offsetY}`,
                      })
                    }
                  />
                </div>
              </div>
              <div className="section-item">
                <div className="section-item-title">Offset Y</div>
                <div className="section-item-content">
                  <PixelPicker
                    value={offsetY}
                    min={-400}
                    max={400}
                    onChange={(value) =>
                      setEvolution({
                        ...evolution,
                        boostedPositionOffsets: `${offsetX};${value}`,
                      })
                    }
                  />
                </div>
              </div>
            </>
          )}
        </>
      )}
      {isFixed && (
        <div className="alignment-figure-wrapper">
          <div className="alignment-figure">
            <div className="alignment-label">
              {selectedAlignment != null ? (
                <>Always show from {selectedAlignment.position}</>
              ) : (
                <>Select a position</>
              )}
            </div>
            {positionsArr.map((o) => {
              if (isHotspot && o.value > 128) {
                return <></>;
              }
              return (
                <div
                  className={classNames('position-dot', o.position, {
                    selected: evolution?.boostedPositionFlags === o.value,
                  })}
                  onClick={() =>
                    setEvolution({...evolution, boostedPositionFlags: o.value})
                  }
                />
              );
            })}
          </div>
        </div>
      )}
      {isTooltip && (
        <>
          <div className="section-item">
            <div className="section-item-title">Target border</div>
            <div className="section-item-content">
              <ColorPickerInput
                title="Target border color"
                value={
                  style.targetBorderColor || style.background?.primaryColor
                }
                onChange={(value) =>
                  handleStyleChange({targetBorderColor: value})
                }
                erasable={style.targetBorderColor != null}
                setParentCloseOnDocumentClick={setCloseOnDocumentClick}
              />
            </div>
          </div>
          <div className="section-item">
            <div className="section-item-title">Pointer</div>
            <div className="section-item-content">
              <RadioGroup
                value={hasPointer}
                options={pointerOptions}
                onSelect={(v) => {
                  if (v === true) {
                    setEvolution({
                      ...evolution,
                      optionsFlags: addFlag(
                        F_OPTION_POKE_CARD_WITH_POINTER,
                        evolution.optionsFlags
                      ),
                    });
                  } else {
                    setEvolution({
                      ...evolution,
                      optionsFlags: removeFlag(
                        F_OPTION_POKE_CARD_WITH_POINTER,
                        evolution.optionsFlags
                      ),
                    });
                  }
                }}
              />
            </div>
          </div>
          {hasPointer === true && (
            <div className="section-item">
              <div className="section-item-title">Pointer color</div>
              <div className="section-item-content">
                <ColorPickerInput
                  title="Pointer color"
                  value={style.pointerColor || style.background?.primaryColor}
                  onChange={(value) =>
                    handleStyleChange({
                      pointerColor: value,
                    })
                  }
                  erasable={style.pointerColor != null}
                  setParentCloseOnDocumentClick={setCloseOnDocumentClick}
                />
              </div>
            </div>
          )}
        </>
      )}
      <div className="section-item z-index">
        <div className="section-item-title">Z-index</div>
        <div className="section-item-content">
          <PopupSetting
            trigger={
              <ClickableInput
                className="position-z-index"
                value={
                  evolution.boostedZIndex != null
                    ? `${evolution.boostedZIndex}`
                    : 'Auto'
                }
                leftLabel={
                  <div className="icon-wrapper">
                    <i className="icon-duplicate" />
                  </div>
                }
              />
            }
            title="Z-index"
            content={
              <ZIndex
                value={evolution.boostedZIndex}
                onChange={(value) =>
                  setEvolution({
                    ...evolution,
                    boostedZIndex: value,
                  })
                }
                setIsOpen={(v) => setCloseOnDocumentClick(!v)}
              />
            }
          />
        </div>
      </div>

      {/* <ModalOpenInAppBuilder
        isOpen={showSelectElementModal}
        onRequestClose={() => setShowSelectElementModal(false)}
        onElementSelected={handleElementSelected}
        handshakeData={{
          type: 'SET_MODE',
          data: {
            mode: 'ELEMENT_SELECTOR_BUILDER',
            onlyInput: false,
          },
        }}
      /> */}
    </div>
  );
};

Position.propTypes = propTypes;
Position.defaultProps = defaultProps;

export default Position;
