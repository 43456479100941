import classNames from 'classnames';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import InputGroup from 'components/Input';
import SelectGroup from 'components/Select';
import {BuilderContext} from 'contextes/builder';
import React, {useContext} from 'react';
import PixelPicker from 'scenes/PokeBuilder/components/BlockEditor/components/items/PixelPicker';
import {
  BLOCK_TYPE_CHOICE,
  BLOCK_TYPE_OPINION,
  STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
  STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
  STEP_TYPE_NPS,
  STEP_TYPE_OPINION_SCALE,
  STEP_TYPE_SLIDER,
  STEP_TYPE_TEXT_LONG,
} from 'services/steps';
import {
  OPINION_TYPE_EMOJI,
  OPINION_TYPE_SMILEY,
  OPINION_TYPE_STAR,
  opinionTypes,
  parseOpinionValue,
} from 'shared/front/components/Poke/components/BlockOpinion';
import './_Styles.scss';

const operatorOptions = [
  {label: 'is equal to', value: 'EQUAL_TO'},
  {label: 'is not equal to', value: 'NOT_EQUAL_TO'},
  {label: 'is greater than', value: 'GREATER_THAN'},
  {label: 'is lesser than', value: 'LESSER_THAN'},
  {label: 'contains', value: 'CONTAINS'},
  {label: 'does not contain', value: 'DOES_NOT_CONTAIN'},
];

const conditionOperatorOptions = [
  {label: 'is exactly', value: 'ALL'},
  {label: 'is not', value: 'NOT'},
  {label: 'contains one of', value: 'AT_LEAST_ONE_OF'},
  {label: 'contains none of', value: 'NONE_OF'},
];

export const parseResponseValue = (operand, values, step) => {
  let valueSpan = '';

  const {blocks = []} = step;
  const blockChoice = blocks?.find((b) => b.type === BLOCK_TYPE_CHOICE);
  const blockOpinion = blocks?.find((b) => b.type === BLOCK_TYPE_OPINION);
  const {options = []} = blockChoice || {};

  const conditionOptions =
    options.map((o) => ({label: o.content, value: o.uid})) || [];

  const opinionValue = parseOpinionValue(blockOpinion?.value || '');

  const isMulti = ['AT_LEAST_ONE_OF', 'NONE_OF'].includes(operand);

  if (operand == null) {
    return null;
  }

  if (step.type === STEP_TYPE_OPINION_SCALE) {
    if (OPINION_TYPE_STAR === opinionValue?.type) {
      if (values) {
        valueSpan = (
          <>
            {new Array(parseInt(values, 10)).fill(0).map(() => (
              <i className="icon-star" style={{color: '#FFC107'}} />
            ))}
          </>
        );
      }
    } else {
      const opinions = JSON.parse(
        JSON.stringify(opinionTypes[opinionValue.type][opinionValue.scale])
      );

      if (values) {
        valueSpan =
          isMulti === true ? (
            (values || '').split(';').filter((n) => n).length > 0 ? (
              opinions
                .filter((o) => values.includes(o.value))
                .map((o, i) => (
                  <span key={i}>
                    {i > 0 ? ', ' : ''}
                    {o.label}
                  </span>
                ))
            ) : isMulti ? (
              'Select value(s)'
            ) : (
              'Select value'
            )
          ) : opinions.find((o) => o.value === values) != null ? (
            <span>{opinions.find((o) => o.value === values).label}</span>
          ) : (
            'Select value'
          );
      }
    }
  } else if (
    [
      STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
      STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
    ].includes(step.type)
  ) {
    if (values) {
      valueSpan = conditionOptions
        .filter((o) => values.split(';').includes(o.value))
        .map((o, i) =>
          i === 0 ? (
            <span key={i} className="qcm-option">
              {o.label}
            </span>
          ) : i === 1 ? (
            <span key={i} className="qcm-option">
              {values.split(';').length - 1}+
            </span>
          ) : (
            <></>
          )
        );
    }
  } else if (step.type === STEP_TYPE_NPS) {
    if (values) {
      valueSpan = values;
    }
  } else if (step.type === STEP_TYPE_SLIDER) {
    if (values) {
      valueSpan = `${values}%`;
    }
  } else if (step.type === STEP_TYPE_TEXT_LONG) {
    if (values) {
      valueSpan = values;
    }
  }

  return (
    <div className="settings-survey-response-readable">
      <span className="settings-survey-response-readable-operator body-3 n-700">
        {operand === 'ALL'
          ? 'is exactly'
          : operand === 'NOT'
          ? 'is not'
          : operand === 'AT_LEAST_ONE_OF'
          ? 'contains one of'
          : operand === 'NONE_OF'
          ? 'contains none of'
          : operand === 'EQUAL_TO'
          ? 'is equal to'
          : operand === 'NOT_EQUAL_TO'
          ? 'is not equal to'
          : operand === 'GREATER_THAN'
          ? 'is greater than'
          : operand === 'LESSER_THAN'
          ? 'is lesser than'
          : operand === 'CONTAINS'
          ? 'contains'
          : operand === 'DOES_NOT_CONTAIN'
          ? 'does not contain'
          : ''}
        {operand != null && ':'}
      </span>
      <span className="settings-survey-response-readable-values">
        {valueSpan}
      </span>
    </div>
  );
};

const SettingsSurveyResponse = ({condition, setCondition, setPreventClose}) => {
  const {selectedStep: step} = useContext(BuilderContext);

  const {blocks = []} = step;
  const blockChoice = blocks?.find((b) => b.type === BLOCK_TYPE_CHOICE);
  const blockOpinion = blocks?.find((b) => b.type === BLOCK_TYPE_OPINION);
  const {options = []} = blockChoice || {};

  const conditionOptions =
    options.map((o) => ({label: o.content, value: o.uid})) || [];

  const {operand, value} = condition;
  const isNumber = [STEP_TYPE_OPINION_SCALE, STEP_TYPE_NPS].includes(step.type);

  const conditionValues = isNumber ? value : value?.split(';') || [];

  const isMulti = ['AT_LEAST_ONE_OF', 'NONE_OF'].includes(operand);

  const opinionValue = parseOpinionValue(blockOpinion?.value || '');

  let opinions = [];
  if (['emoji', 'smiley'].includes(opinionValue.type)) {
    opinions = JSON.parse(
      JSON.stringify(opinionTypes[opinionValue.type][opinionValue.scale])
    );
  } else if (opinionValue.type === 'star') {
    opinions = new Array(opinionValue.scale).fill(0).map((_, i) => {
      return {
        value: `${i + 1}`,
        label: (
          <>
            {new Array(i + 1).fill(0).map(() => (
              <i className="icon-star" style={{color: '#FFC107'}} />
            ))}
          </>
        ),
      };
    });
  }

  return (
    <div className="settings-survey-response">
      <div className="section">
        <div className="body-3 n-700">Answer</div>
        {[
          STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
          STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
        ].includes(step.type) ||
        (STEP_TYPE_OPINION_SCALE === step.type &&
          [OPINION_TYPE_SMILEY, OPINION_TYPE_EMOJI].includes(
            opinionValue.type
          )) ? (
          <div className="condition-operator-wrapper">
            <SelectGroup
              isSearchable={false}
              options={conditionOperatorOptions}
              onChange={(option) => {
                setCondition({
                  ...condition,
                  operand: option.value,
                  value: '',
                });
              }}
              className={classNames('condition-operator-select')}
              value={conditionOperatorOptions.find((c) => c.value === operand)}
            />
          </div>
        ) : [STEP_TYPE_SLIDER, STEP_TYPE_TEXT_LONG, STEP_TYPE_NPS].includes(
            step.type
          ) ||
          (step.type === STEP_TYPE_OPINION_SCALE &&
            OPINION_TYPE_STAR === opinionValue?.type) ? (
          <SelectGroup
            isSearchable={false}
            className="condition-operator-select"
            menuPortalTarget={document.body}
            options={
              [
                STEP_TYPE_SLIDER,
                STEP_TYPE_NPS,
                STEP_TYPE_OPINION_SCALE,
              ].includes(step.type)
                ? operatorOptions.filter((e) =>
                    [
                      'EQUAL_TO',
                      'NOT_EQUAL_TO',
                      'GREATER_THAN',
                      'LESSER_THAN',
                    ].includes(e.value)
                  )
                : operatorOptions.filter((e) =>
                    [
                      'EQUAL_TO',
                      'NOT_EQUAL_TO',
                      'CONTAINS',
                      'DOES_NOT_CONTAIN',
                    ].includes(e.value)
                  )
            }
            placeholder="Select an operator"
            closeMenuOnSelect
            onChange={(option) => {
              setCondition({
                ...condition,
                operand: option.value,
                value: '',
              });
            }}
            value={operatorOptions?.find((c) => c.value === operand)}
          />
        ) : null}
      </div>
      <div className="section">
        <div className="body-3 n-700">Condition</div>
        {[
          STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
          STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
        ].includes(step.type) && (
          <SelectGroup
            isSearchable={false}
            className="condition-values-select"
            menuPortalTarget={document.body}
            options={conditionOptions}
            placeholder="Select an answer"
            isMulti={
              step.type === STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT ||
              ['AT_LEAST_ONE', 'NONE'].includes(operand)
            }
            closeMenuOnSelect={
              step.type === STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT ||
              ['AT_LEAST_ONE', 'NONE'].includes(operand)
            }
            onChange={(options) => {
              setCondition({
                ...condition,
                value: options.map((o) => o.value).join(';'),
              });
            }}
            value={conditionOptions?.filter((c) =>
              conditionValues?.map((v) => v.uid || v).includes(c.value)
            )}
            isDisabled={false}
          />
        )}
        {step.type === STEP_TYPE_OPINION_SCALE && (
          <Dropdown
            className="users-responses-dropdown"
            position="bottom left"
            triggerClassName={classNames({
              'is-set': false,
            })}
            contentStyle={{zIndex: 1002}}
            repositionOnResize={false}
            onOpen={() => setPreventClose(true)}
            onClose={() => setPreventClose(false)}
            trigger={
              <Button
                className="opinion-scale-dropdown-btn body-4"
                thin
                iconRight="icon-chevron-bottom">
                {isMulti === true ? (
                  (value || '').split(';').filter((n) => n).length > 0 ? (
                    opinions
                      .filter((o) => conditionValues.includes(o.value))
                      .map((o, i) => (
                        <span key={i}>
                          {i > 0 ? ', ' : ''}
                          {o.label}
                        </span>
                      ))
                  ) : isMulti ? (
                    'Select value(s)'
                  ) : (
                    'Select value'
                  )
                ) : opinions.find((o) => o.value === value) != null ? (
                  <span>{opinions.find((o) => o.value === value).label}</span>
                ) : (
                  'Select value'
                )}
              </Button>
            }>
            <div className="setting-list">
              {opinions?.map((option) => {
                const isSelected = isMulti
                  ? (value || '').split(';').includes(option.value)
                  : value === option.value;
                return (
                  <div
                    className={classNames('setting-item', {
                      'is-set': isSelected,
                    })}
                    onClick={() => {
                      const alreadyHasValue =
                        isMulti &&
                        (value || '').split(';').includes(option.value);
                      let newValue;
                      if (isMulti) {
                        if (alreadyHasValue) {
                          newValue = value
                            .split(';')
                            .filter((v) => v !== option.value)
                            .join(';');
                        } else {
                          newValue = [
                            ...(value || '').split(';'),
                            option.value,
                          ].join(';');
                        }
                      } else {
                        newValue = option.value;
                      }
                      setCondition({
                        ...condition,
                        value: newValue,
                      });
                    }}>
                    {isMulti && (
                      <>
                        {isSelected ? (
                          <i className="icon-checkbox"></i>
                        ) : (
                          <i className="icon-checkbox-o"></i>
                        )}
                      </>
                    )}
                    <div className="option-info">
                      <div className="option-label">{option.label}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </Dropdown>
        )}
        {[STEP_TYPE_TEXT_LONG, STEP_TYPE_NPS].includes(step.type) && (
          <InputGroup
            required
            placeholder={
              step.type === STEP_TYPE_OPINION_SCALE
                ? 'Stars amount'
                : step.type === STEP_TYPE_NPS
                ? 'Number 1-10'
                : ''
            }
            className="value-input"
            name="value"
            type={
              [STEP_TYPE_OPINION_SCALE, STEP_TYPE_NPS].includes(step.type)
                ? 'number'
                : 'string'
            }
            min={0}
            max={
              step.type === STEP_TYPE_OPINION_SCALE
                ? opinionValue?.scale
                : step.type === STEP_TYPE_NPS
                ? 10
                : 100
            }
            value={value || ''}
            onChange={(e) => {
              let correctedValue = e.target.value;
              if (step.type === STEP_TYPE_NPS) {
                correctedValue =
                  correctedValue > 10 ? 10 : Math.max(1, correctedValue);
              }

              setCondition({
                ...condition,
                value: correctedValue?.toString(),
              });
            }}
          />
        )}
        {step.type === STEP_TYPE_SLIDER && (
          <PixelPicker
            value={value || 0}
            min={0}
            max={100}
            label="%"
            onChange={(value) => {
              setCondition({
                ...condition,
                value: value?.toString() || '0',
              });
            }}
          />
        )}
      </div>
    </div>
  );
};

export default SettingsSurveyResponse;
