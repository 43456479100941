import React, {useContext, useState} from 'react';
import './_Styles.scss';
import {
  RECURRENCY_EVERY_TIME,
  RECURRENCY_SINGLE_TIME,
  RECURRENCY_DAY_BASED,
  RECURRENCY_WEEK_BASED,
  RECURRENCY_MONTH_BASED,
} from 'services/evolution';
import Input from 'components/Input';
import {AudienceContext} from 'scenes/PokeAudience';
import {InteractiveOption} from 'components/InteractiveOption';

const recurrenceOptions = [
  {content: 'Day', value: RECURRENCY_DAY_BASED},
  {content: 'Week', value: RECURRENCY_WEEK_BASED},
  {content: 'Month', value: RECURRENCY_MONTH_BASED},
];

const RecurrencySelection = () => {
  const {evolution, setEvolution} = useContext(AudienceContext);

  const [unit, setUnit] = useState(
    recurrenceOptions.find((o) => o.value === evolution?.recurrencyType)
      ?.value || RECURRENCY_DAY_BASED
  );

  return (
    <div className="poke-recurrence-options">
      <InteractiveOption
        className="recurrence-option"
        withRadioBox
        iconBoxPosition="left"
        active={evolution.recurrencyType === RECURRENCY_SINGLE_TIME}
        title="Show once"
        onClick={() =>
          setEvolution({
            ...evolution,
            recurrencyType: RECURRENCY_SINGLE_TIME,
          })
        }
      />
      <InteractiveOption
        className="recurrence-option"
        withRadioBox
        iconBoxPosition="left"
        active={
          evolution.recurrencyType !== RECURRENCY_SINGLE_TIME &&
          evolution.recurrencyType !== RECURRENCY_EVERY_TIME
        }
        title={`Recurring ${evolution.type.toLowerCase()} every`}
        onClick={() =>
          setEvolution({
            ...evolution,
            recurrencyType: RECURRENCY_DAY_BASED,
          })
        }
      >
        <Input
          small
          type="number"
          min={0}
          option
          optionValue={unit}
          optionItems={recurrenceOptions}
          onOptionSelected={(selectedRecurrencyType) => {
            setEvolution({
              ...evolution,
              recurrencyType: selectedRecurrencyType,
            });
            setUnit(selectedRecurrencyType);
          }}
          onChange={({target}) =>
            setEvolution({
              ...evolution,
              recurrencyValue: parseInt(target.value),
            })
          }
          value={evolution?.recurrencyValue || 0}
        />
      </InteractiveOption>
      <InteractiveOption
        className="recurrence-option"
        withRadioBox
        iconBoxPosition="left"
        active={evolution.recurrencyType === RECURRENCY_EVERY_TIME}
        title="Every time"
        onClick={() =>
          setEvolution({
            ...evolution,
            recurrencyType: RECURRENCY_EVERY_TIME,
          })
        }
      />
    </div>
  );
};

export default RecurrencySelection;
