import React, {useContext} from 'react';
import {AudienceContext, SECTION_WHEN} from 'scenes/PokeAudience';
import AudienceSection from 'scenes/PokeAudience/components/AudienceSection';
import './_Styles.scss';

import Divider from 'components/Divider';
import Label from 'components/Label';
import {hasFlag} from 'helpers/bitwise';
import {bool, number} from 'prop-types';
import {
  EVOLUTION_TYPE_HINT,
  F_OPTION_IGNORE_RATE_LIMITING,
  RECURRENCY_EVERY_TIME,
  RECURRENCY_SINGLE_TIME,
  RECURRENCY_DAY_BASED,
  RECURRENCY_WEEK_BASED,
  RECURRENCY_MONTH_BASED,
} from 'services/evolution';
import Priority from './components/Priority';
import RecurrencySelection from './components/RecurrencySelection';
import TimingSelection, {dateToString} from './components/TimingSelection';

export const getWhenTitle = (
  evolution,
  {isPost = false, isHint = false, isDraft = false} = {}
) => {
  const recurrency = evolution?.recurrencyType;
  const recurrencyValue = evolution?.recurrencyValue;
  const priority = evolution?.priority || 0;
  const ignorePokeControl = hasFlag(
    F_OPTION_IGNORE_RATE_LIMITING,
    evolution?.optionsFlags
  );

  const curDate = new Date();
  let publishAtLabel = 'Now';
  let expiresAtLabel = 'No expiration';

  if (new Date(evolution.lastStepChangeAt).getTime() > curDate.getTime()) {
    publishAtLabel = `Live on ${dateToString(evolution.lastStepChangeAt)}`;
  }
  if (
    new Date(evolution.lastStepChangeAt).getTime() < curDate.getTime() &&
    evolution.isDraft !== true
  ) {
    publishAtLabel = `Live since ${dateToString(evolution.lastStepChangeAt)}`;
  }

  if (evolution.expiresAt != null) {
    if (new Date(evolution.expiresAt).getTime() < curDate.getTime()) {
      expiresAtLabel = `Expired on ${dateToString(evolution.expiresAt)}`;
    }
    if (new Date(evolution.expiresAt).getTime() > curDate.getTime()) {
      expiresAtLabel = `Expires on ${dateToString(evolution.expiresAt)}`;
    }
  }

  const mapRecurrencyTypeToStr = {
    [RECURRENCY_DAY_BASED]: 'day',
    [RECURRENCY_WEEK_BASED]: 'week',
    [RECURRENCY_MONTH_BASED]: 'month',
  };

  const recurrencyStr =
    recurrency === RECURRENCY_SINGLE_TIME
      ? 'Show once'
      : recurrency === RECURRENCY_EVERY_TIME
        ? 'Show every time'
        : `Show every ${recurrencyValue} ${mapRecurrencyTypeToStr[recurrency]}${recurrencyValue > 1 ? 's' : ''}`;
  const priorityStr =
    ignorePokeControl === true
      ? 'Ignore rate limiting'
      : `Priority ${priority}`;

  if (isPost === true || isHint === true) {
    return `${publishAtLabel}, ${expiresAtLabel}`;
  }
  return (
    <>
      {publishAtLabel} &bull; {expiresAtLabel} &bull; {recurrencyStr} &bull;{' '}
      {priorityStr}
    </>
  );
};

const propTypes = {
  index: number.isRequired,
  isPost: bool,
};

const When = ({isPost = false}) => {
  const {evolution} = useContext(AudienceContext);

  const isHint = evolution?.type === EVOLUTION_TYPE_HINT;
  const isDraft = evolution?.isDraft === true;

  return (
    <AudienceSection
      className="when"
      section={SECTION_WHEN}
      title="When"
      subtitle={
        <Label secondary size="small" type="neutral">
          {getWhenTitle(evolution, {isPost, isHint, isDraft})}
        </Label>
      }
      icon={<i className="isax isax-calendar-1" />}
    >
      <div className="sub-section-title body-3 n-700">
        Select when the experience is live
      </div>
      <TimingSelection />
      {isPost !== true && isHint !== true && (
        <>
          <Divider />
          <div className="sub-section-title body-3 n-700">
            Select experience recurrence
          </div>
          <RecurrencySelection />
          <Divider />
          <div className="sub-section-title body-3 n-700">
            Select experience priority
          </div>
          <Priority />
        </>
      )}
    </AudienceSection>
  );
};

When.propTypes = propTypes;

export default When;
